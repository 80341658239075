import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlus, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { API_URL } from "../config";
import { getCurrentDate } from '../Components/DateFunction';
import Swal from 'sweetalert2';

const Donations = () => {
    const [donations, setDonations] = useState([]);
    const [filteredDonations, setFilteredDonations] = useState([]);
    const [members, setMembers] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentDonation, setCurrentDonation] = useState(null);

    const [formData, setFormData] = useState({
        id: '',
        date: '',
        item: '',
        reason: '',
        memberId: ''
    });

    const [totalDonations, setTotalDonations] = useState(0);

    // Filters
    const [selectedReason, setSelectedReason] = useState('');
    const [selectedItem, setSelectedItem] = useState('');
    const [selectedMemberName, setSelectedMemberName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // Sorting state
    const [sortField, setSortField] = useState('Date');
    const [sortOrder, setSortOrder] = useState('asc');

    // Search state for member selection
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredMembers, setFilteredMembers] = useState([]);

    const fetchDonations = async () => {
        const response = await fetch(`${API_URL}/donations`);
        const data = await response.json();
        setDonations(data);
        setTotalDonations(data.length)
        setFilteredDonations(data);
    };

    const fetchMembers = async () => {
        const response = await fetch(`${API_URL}/members`);
        const data = await response.json();
        setMembers(data);
        setFilteredMembers(data);
    };

    useEffect(() => {
        fetchDonations();
        fetchMembers();
    }, []);

    useEffect(() => {
        const matches = members.filter(member =>
            member.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (member.Email && member.Email.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (member.Surname && member.Surname.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        setFilteredMembers(matches);

        if (matches.length === 1) {
            setFormData(prevData => ({ ...prevData, MemberID: matches[0].MemberID }));
        } else {
            if (matches.length === 0 || matches.length > 1) {
                setFormData(prevData => ({ ...prevData, MemberID: '' }));
            }
        }
    }, [searchTerm, members]);

    const handleAddDonation = () => {
        setFormData({
            id: '',
            date: '',
            item: '',
            reason: '',
            memberId: ''
        });
        setShowAddModal(true);
    };

    const handleEditDonation = (donation) => {
        setCurrentDonation(donation);
        setFormData({
            id: donation.id,
            date: donation.date.slice(0, 10),
            item: donation.item,
            reason: donation.reason,
            memberId: donation.memberID
        });
        setShowEditModal(true);
    };


    const handleDeleteDonation = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });

        if (result.isConfirmed) {
            try {
                await fetch(`${API_URL}/donations/${id}`, {
                    method: 'DELETE',
                });
                fetchDonations();
                Swal.fire({
                    text: "Deleted Successfully!",
                    icon: "success"
                });
            } catch (error) {
                Swal.fire({
                    text: "An error occurred while deleting!",
                    icon: "error"
                });
            }
        }
    };


    const handleFilter = () => {
        let results = donations;


        if (selectedItem) {
            results = results.filter(donation => donation.item.toLowerCase() === selectedItem.toLowerCase());
        }
        if (selectedReason) {
            results = results.filter(donation => donation.reason.toLowerCase().includes(selectedReason.toLowerCase()));
        }
        if (selectedMemberName) {
            results = results.filter(donation => {
                const member = members.find(m => `${m.Name} ${m.Surname}`.toLowerCase() === selectedMemberName.toLowerCase());
                return member && donation.MemberID === member.MemberID;
            });
        }
        if (startDate) {
            results = results.filter(donation => new Date(donation.date) >= new Date(startDate));
        }
        if (endDate) {
            results = results.filter(donation => new Date(donation.date) <= new Date(endDate));
        }

        setFilteredDonations(results);
        setTotalDonations(results.length)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let response;
            if (currentDonation) {
                // console.log(currentDonation);
                // Update existing contribution
                response = await fetch(`${API_URL}/donations/${currentDonation.id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formData),
                });
                if (!response.ok) throw new Error('Update failed');
                setShowEditModal(false);
                Swal.fire({
                    text: "Updated Successfully!",
                    icon: "success"
                });
                setShowAddModal(false);
            } else {
                // Add new contribution
                response = await fetch(`${API_URL}/donations`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formData),
                });
                if (!response.ok) throw new Error('Addition failed');
                setShowAddModal(false);
                Swal.fire({
                    text: "Added Successfully!",
                    icon: "success"
                });
                setShowAddModal(false);
            }
            fetchDonations();
        } catch (error) {
            Swal.fire({
                text: error.message || "An error occurred!",
                icon: "error"
            });
        }
    };


    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
        const sortedDonations = [...filteredDonations].sort((a, b) => {
            if (order === 'asc') {
                return a[field] < b[field] ? -1 : 1;
            } else {
                return a[field] > b[field] ? -1 : 1;
            }
        });
        setFilteredDonations(sortedDonations);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    // Function to get member name by ID
    const getMemberName = (memberID) => {
        const member = members.find(m => m.MemberID === memberID);
        return member ? `${member.Name} ${member.Surname}` : 'Unknown';
    };


    const generatePDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(20);
        doc.text('New Life Covenant Church', 14, 22);
        doc.setFontSize(15);
        doc.text(`Donations Report ${getCurrentDate()}`, 14, 35);

        autoTable(doc, {
            startY: 62,
            head: [['Date', 'Member Name', 'Item', 'Reason']],
            body: filteredDonations.map(donation => [
                new Date(donation.date).toLocaleDateString(),
                getMemberName(donation.memberID),
                donation.item,
                donation.reason
            ]),
        });

        doc.save(`donations_report_${getCurrentDate()}.pdf`);
    };

    const generateCSV = () => {
        const csvRows = [];

        // Add header row
        csvRows.push(['Date', 'Member Name', 'Item', 'Reason'].join(','));

        // Add data rows
        filteredDonations.forEach(donation => {
            const row = [
                new Date(donation.date).toLocaleDateString(),
                getMemberName(donation.memberID),
                donation.item,
                donation.reason
            ];
            csvRows.push(row.join(','));
        });

        // Create a CSV string
        const csvString = csvRows.join('\n');

        const blob = new Blob([csvString], { type: 'text/csv' });

        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `donations,_report__${getCurrentDate()}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return (
        <div>
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Topnav />
                    <div className="container">
                        <div className="page-inner">
                            <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
                                <h2 className="text-capitalize font-weight-bold">
                                    Donations ({totalDonations})
                                </h2>
                                <div>
                                    <button className="btn btn-sm" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={generateCSV}>
                                        Generate CSV
                                    </button> &nbsp;
                                    <button className="btn btn-sm" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={generatePDF}>
                                        Generate PDF
                                    </button> &nbsp;
                                    <button className="btn btn-sm" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={handleAddDonation}>
                                        <FontAwesomeIcon icon={faPlus} /> Add Donation
                                    </button>
                                </div>
                            </div>

                            {/* Filters */}
                            <div className="d-flex mb-3">
                                <input
                                    type="text"
                                    className="form-control mr-2"
                                    placeholder="Filter by Item"
                                    value={selectedItem}
                                    onChange={(e) => setSelectedItem(e.target.value)}
                                />
                                {/* <input
                                    type="text"
                                    className="form-control mr-2"
                                    placeholder="Filter by Member Name"
                                    value={selectedMemberName}
                                    onChange={(e) => setSelectedMemberName(e.target.value)}
                                /> */}
                                <input
                                    type="text"
                                    className="form-control mr-2"
                                    placeholder="Filter by Reason"
                                    value={selectedReason}
                                    onChange={(e) => setSelectedReason(e.target.value)}
                                />
                                <input
                                    type="date"
                                    className="form-control mr-2"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <input
                                    type="date"
                                    className="form-control mr-2"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                                <button className="btn btn-secondary" onClick={handleFilter}>Filter</button>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th onClick={() => handleSort('Date')} style={{ cursor: 'pointer' }}>
                                                Date {getSortIcon('Date')}
                                            </th>
                                            <th> Member Name</th>
                                            <th> Item</th>
                                            <th> Reason</th>
                                            <th> Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredDonations.map((donation) => (
                                            <tr key={donation.id}>
                                                <td>{new Date(donation.date).toLocaleDateString()}</td>
                                                <td>{getMemberName(donation.memberID)}</td>
                                                {/* <td>${contribution.Amount.toFixed(2)}</td> */}
                                                <td>{donation.item}</td>
                                                <td>{donation.reason}</td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <button type="button" className="btn btn-link" onClick={() => handleEditDonation(donation)}>
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </button>
                                                        <button type="button" className="btn btn-link" onClick={() => handleDeleteDonation(donation.id)}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <Footer />
                    </footer>
                </div>
            </div>

            {/* Add Contribution Modal */}
            {showAddModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowAddModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Add Donation</h5>
                                <button type="button" className="close" onClick={() => setShowAddModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="modal-label">Member</label>
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            placeholder="Search by Name or Email"
                                            style={{ backgroundColor: '#c3f3f580' }}
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <select
                                            className="form-control"
                                            value={formData.memberId}
                                            onChange={(e) => setFormData(prevData => ({ ...prevData, memberId: e.target.value }))}
                                            required
                                        >
                                            <option value="">Select Member</option>
                                            {filteredMembers.map(member => (
                                                <option key={member.MemberID} value={member.MemberID}>
                                                    {member.Name} {member.Surname} {member.Email ? `(${member.Email})` : ''}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Date</label>
                                        <input type="date" className="form-control" value={formData.date} onChange={(e) => setFormData(prevData => ({ ...prevData, date: e.target.value }))} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Item</label>
                                        <input type="text" className="form-control" value={formData.item} onChange={(e) => setFormData(prevData => ({ ...prevData, item: e.target.value }))} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Reason</label>
                                        <input type="text" className="form-control" value={formData.reason} onChange={(e) => setFormData(prevData => ({ ...prevData, reason: e.target.value }))} required />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowAddModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Add Donation</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Contribution Modal */}
            {showEditModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowEditModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Donation</h5>
                                <button type="button" className="close" style={{ float: 'right' }} onClick={() => setShowEditModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="modal-label">Member</label>
                                        <select
                                            className="form-control"
                                            value={formData.memberId}
                                            onChange={(e) => setFormData(prevData => ({ ...prevData, memberId: e.target.value }))}
                                            required
                                        >
                                            <option value="">Select Member</option>
                                            {filteredMembers.map(member => (
                                                <option key={member.MemberID} value={member.MemberID}>
                                                    {member.Name} {member.Surname} {member.Email ? `(${member.Email})` : ''}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Date</label>
                                        <input type="date" className="form-control" value={formData.date} onChange={(e) => setFormData(prevData => ({ ...prevData, date: e.target.value }))} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Item</label>
                                        <input type="text" className="form-control" value={formData.item} onChange={(e) => setFormData(prevData => ({ ...prevData, item: e.target.value }))} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Reason</label>
                                        <input type="text" className="form-control" value={formData.reason} onChange={(e) => setFormData(prevData => ({ ...prevData, reason: e.target.value }))} required />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update Donation</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Donations;

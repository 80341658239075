const Footer = () => {
    return (
        <div className="container-fluid d-flex justify-content-between">
            <nav className="pull-left">
                <ul className="nav">
                    <li className="nav-item">
                        <a className="nav-link" href="https://www.jabulanlcc.org/" target="_blank" rel="noopener noreferrer">
                            New Life Covenant Church
                        </a>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link" href="0">Help</a>
                    </li> */}
                    {/* Add more Footer links as needed */}
                </ul>
            </nav>
        </div>
    );
}

export default Footer;
import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlus, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { API_URL } from "../config";
import { getCurrentDate } from '../Components/DateFunction';
import Swal from 'sweetalert2';

const Contributions = () => {
    const [contributions, setContributions] = useState([]);
    const [filteredContributions, setFilteredContributions] = useState([]);
    const [members, setMembers] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [projects, setProjects] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentContribution, setCurrentContribution] = useState(null);

    const [formData, setFormData] = useState({
        MemberID: '',
        Date: '',
        Amount: '',
        Method: '',
        PledgeID: null,
        ProjectID: '',
        currency: ''
    });

    const [totalContributions, setTotalContributions] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    // Filters
    const [selectedMemberName, setSelectedMemberName] = useState('');
    const [selectedProject, setSelectedProject] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // Sorting state
    const [sortField, setSortField] = useState('Date');
    const [sortOrder, setSortOrder] = useState('asc');

    // Search state for member selection
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredMembers, setFilteredMembers] = useState([]);

    const fetchContributions = async () => {
        const response = await fetch(`${API_URL}/contributions`);
        const data = await response.json();
        setContributions(data);
        setFilteredContributions(data);
    };

    const fetchMembers = async () => {
        const response = await fetch(`${API_URL}/members`);
        const data = await response.json();
        setMembers(data);
        setFilteredMembers(data);
    };

    const fetchProjects = async () => {
        const response = await fetch(`${API_URL}/projects`);
        const data = await response.json();
        setProjects(data);
    };

    // const calculateTotals = useCallback(() => {
    //     const totalContribs = filteredContributions.length;
    //     const totalSum = filteredContributions.reduce((sum, contrib) => sum + contrib.Amount, 0);
    //     setTotalContributions(totalContribs);
    //     setTotalAmount(totalSum);
    // }, [filteredContributions]);

    const calculateTotals = useCallback(() => {
        const totalContribs = filteredContributions.length;
        const totalSum = filteredContributions.reduce((sum, contrib) => {
            // Check if contrib.currency is valid before comparing
            if (contrib.currency && contrib.currency.toLowerCase() === selectedCurrency.toLowerCase()) {
                return sum + contrib.Amount;
            }
            return sum;
        }, 0);
        setTotalContributions(totalContribs);
        setTotalAmount(totalSum);
    }, [filteredContributions, selectedCurrency]);
    

    useEffect(() => {
        fetchContributions();
        fetchMembers();
        fetchProjects();
    }, []);

    useEffect(() => {
        calculateTotals();
    }, [filteredContributions, calculateTotals]);

    useEffect(() => {
        const matches = members.filter(member =>
            member.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (member.Email && member.Email.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (member.Surname && member.Surname.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        setFilteredMembers(matches);

        if (matches.length === 1) {
            setFormData(prevData => ({ ...prevData, MemberID: matches[0].MemberID }));
        } else {
            if (matches.length === 0 || matches.length > 1) {
                setFormData(prevData => ({ ...prevData, MemberID: '' }));
            }
        }
    }, [searchTerm, members]);

    const handleAddContribution = () => {
        setFormData({
            MemberID: '',
            Date: '',
            Amount: '',
            Method: '',
            PledgeID: null,
            ProjectID: ''
        });
        setShowAddModal(true);
    };

    const handleEditContribution = (contribution) => {
        setCurrentContribution(contribution);
        setFormData({
            MemberID: contribution.MemberID,
            Date: contribution.Date.slice(0, 10),
            Amount: contribution.Amount,
            currency: contribution.currency,
            ProjectID: contribution.ProjectID
        });
        setShowEditModal(true);
    };

    // const handleDeleteContribution = async (id) => {
    //     if (window.confirm("Are you sure you want to delete this contribution?")) {
    //         await fetch(`${API_URL}/contributions/${id}`, {
    //             method: 'DELETE',
    //         });
    //         fetchContributions();
    //     }
    // };

    const handleDeleteContribution = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });
    
        if (result.isConfirmed) {
            try {
                await fetch(`${API_URL}/contributions/${id}`, {
                    method: 'DELETE',
                });
                fetchContributions();
                Swal.fire({
                    text: "Deleted Successfully!",
                    icon: "success"
                });
            } catch (error) {
                Swal.fire({
                    text: "An error occurred while deleting!",
                    icon: "error"
                });
            }
        }
    };
    

    const handleFilter = () => {
        let results = contributions;


        if (selectedCurrency) {
            results = results.filter(contrib => contrib.currency.toLowerCase() === selectedCurrency.toLowerCase());
        }
        if (selectedMemberName) {
            results = results.filter(contrib => {
                const member = members.find(m => `${m.Name} ${m.Surname}`.toLowerCase() === selectedMemberName.toLowerCase());
                return member && contrib.MemberID === member.MemberID;
            });
        }
        if (selectedProject) {
            results = results.filter(contrib => {
                const project = projects.find(p => p.ProjectID === contrib.ProjectID);
                return project && project.ProjectName.toLowerCase().includes(selectedProject.toLowerCase());
            });
        }
        if (startDate) {
            results = results.filter(contrib => new Date(contrib.Date) >= new Date(startDate));
        }
        if (endDate) {
            results = results.filter(contrib => new Date(contrib.Date) <= new Date(endDate));
        }

        setFilteredContributions(results);
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     if (currentContribution) {
    //         // Update existing contribution
    //         await fetch(`${API_URL}/contributions/${currentContribution.ContributionID}`, {
    //             method: 'PUT',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify(formData),
    //         });
    //         setShowEditModal(false);
    //     } else {
    //         // Add new contribution
    //         await fetch(`${API_URL}/contributions`, {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify(formData),
    //         });
    //         setShowAddModal(false);
    //     }
    //     fetchContributions();
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let response;
            if (currentContribution) {
                // Update existing contribution
                response = await fetch(`${API_URL}/contributions/${currentContribution.ContributionID}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formData),
                });
                if (!response.ok) throw new Error('Update failed');
                setShowEditModal(false);
                Swal.fire({
                    text: "Updated Successfully!",
                    icon: "success"
                });
                setShowAddModal(false);
            } else {
                // Add new contribution
                response = await fetch(`${API_URL}/contributions`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formData),
                });
                if (!response.ok) throw new Error('Addition failed');
                setShowAddModal(false);
                Swal.fire({
                    text: "Added Successfully!",
                    icon: "success"
                });
                setShowAddModal(false);
            }
            fetchContributions();
        } catch (error) {
            Swal.fire({
                text: error.message || "An error occurred!",
                icon: "error"
            });
        }
    };
    

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
        const sortedContributions = [...filteredContributions].sort((a, b) => {
            if (order === 'asc') {
                return a[field] < b[field] ? -1 : 1;
            } else {
                return a[field] > b[field] ? -1 : 1;
            }
        });
        setFilteredContributions(sortedContributions);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    // Function to get member name by ID
    const getMemberName = (memberID) => {
        const member = members.find(m => m.MemberID === memberID);
        return member ? `${member.Name} ${member.Surname}` : 'Unknown';
    };

    // Function to get project name by ID
    const getProjectName = (projectID) => {
        const project = projects.find(p => p.ProjectID === projectID);
        return project ? project.ProjectName : 'Unknown';
    };

    const generatePDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(20);
        doc.text('New Life Covenant Church', 14, 22);
        doc.setFontSize(15);
        doc.text(`Contribution Report ${getCurrentDate()}`, 14, 35);
        doc.setFontSize(12);
        doc.text(`Total Contributions: $${totalAmount.toFixed(2)}`, 14, 52);

        autoTable(doc, {
            startY: 62,
            head: [['Date', 'Member Name', 'Amount', 'Project']],
            body: filteredContributions.map(contribution => [
                new Date(contribution.Date).toLocaleDateString(),
                getMemberName(contribution.MemberID),
                `$${contribution.Amount.toFixed(2)}`,
                getProjectName(contribution.ProjectID)
            ]),
        });

        doc.save('contribution_report.pdf');
    };

    const generateCSV = () => {
        const csvRows = [];
        
        // Add header row
        csvRows.push(['Date', 'Member Name', 'Amount', 'Currency', 'Category'].join(','));
    
        // Add data rows
        filteredContributions.forEach(contribution => {
            const row = [
                new Date(contribution.Date).toLocaleDateString(),
                getMemberName(contribution.MemberID),
                contribution.Amount.toFixed(2),
                contribution.currency,
                getProjectName(contribution.ProjectID)
            ];
            csvRows.push(row.join(','));
        });
    
        // Create a CSV string
        const csvString = csvRows.join('\n');
    
        const blob = new Blob([csvString], { type: 'text/csv' });
    
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `contribution_report__${getCurrentDate()}.csv`; 
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url); 
    };    

    return (
        <div>
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Topnav />
                    <div className="container">
                        <div className="page-inner">
                            <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
                                {/* <h2 className="text-capitalize font-weight-bold">
                                    Contributions ({totalContributions}) - Total Amount: ${totalAmount.toFixed(2)}
                                </h2> */}
                                <h2 className="text-capitalize font-weight-bold">
                                    Contributions ({totalContributions})
                                    {selectedCurrency && ` - Total Amount: ${selectedCurrency} ${totalAmount.toFixed(2)}`}
                                </h2>
                                <div>
                                    <button className="btn btn-sm" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={generateCSV}>
                                        Generate CSV
                                    </button> &nbsp;
                                    <button className="btn btn-sm" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={generatePDF}>
                                        Generate PDF
                                    </button> &nbsp;
                                    <button className="btn btn-sm" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={handleAddContribution}>
                                        <FontAwesomeIcon icon={faPlus} /> Add Contribution
                                    </button>
                                </div>
                            </div>

                            {/* Filters */}
                            <div className="d-flex mb-3">
                                <input
                                    type="text"
                                    className="form-control mr-2"
                                    placeholder="Filter by Currency"
                                    value={selectedCurrency}
                                    onChange={(e) => setSelectedCurrency(e.target.value)}
                                />
                                {/* <input
                                    type="text"
                                    className="form-control mr-2"
                                    placeholder="Filter by Member Name"
                                    value={selectedMemberName}
                                    onChange={(e) => setSelectedMemberName(e.target.value)}
                                /> */}
                                <input
                                    type="text"
                                    className="form-control mr-2"
                                    placeholder="Filter by category"
                                    value={selectedProject}
                                    onChange={(e) => setSelectedProject(e.target.value)}
                                />
                                <input
                                    type="date"
                                    className="form-control mr-2"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <input
                                    type="date"
                                    className="form-control mr-2"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                                <button className="btn btn-secondary" onClick={handleFilter}>Filter</button>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th onClick={() => handleSort('Date')} style={{ cursor: 'pointer' }}>
                                                Date {getSortIcon('Date')}
                                            </th>
                                            <th> Member Name</th>
                                            <th> Amount</th>
                                            <th> Currency</th>
                                            <th> Category </th>
                                            <th> Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredContributions.map((contribution) => (
                                            <tr key={contribution.ContributionID}>
                                                <td>{new Date(contribution.Date).toLocaleDateString()}</td>
                                                <td>{getMemberName(contribution.MemberID)}</td>
                                                {/* <td>${contribution.Amount.toFixed(2)}</td> */}
                                                <td>${contribution.Amount ? contribution.Amount.toFixed(2) : '0.00'}</td>
                                                <td>{contribution.currency}</td>
                                                <td>{getProjectName(contribution.ProjectID)}</td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <button type="button" className="btn btn-link" onClick={() => handleEditContribution(contribution)}>
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </button>
                                                        <button type="button" className="btn btn-link" onClick={() => handleDeleteContribution(contribution.ContributionID)}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <Footer />
                    </footer>
                </div>
            </div>

            {/* Add Contribution Modal */}
            {showAddModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowAddModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Add Contribution</h5>
                                <button type="button" className="close" onClick={() => setShowAddModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="modal-label">Member</label>
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            placeholder="Search by Name or Email"
                                            style={{backgroundColor: '#c3f3f580'}}
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <select
                                            className="form-control"
                                            value={formData.MemberID}
                                            onChange={(e) => setFormData(prevData => ({ ...prevData, MemberID: e.target.value }))}
                                            required
                                        >
                                            <option value="">Select Member</option>
                                            {filteredMembers.map(member => (
                                                <option key={member.MemberID} value={member.MemberID}>
                                                    {member.Name} {member.Surname} {member.Email ? `(${member.Email})` : ''}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Date</label>
                                        <input type="date" className="form-control" value={formData.Date} onChange={(e) => setFormData(prevData => ({ ...prevData, Date: e.target.value }))} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Amount</label>
                                        <input type="number" className="form-control" value={formData.Amount} onChange={(e) => setFormData(prevData => ({ ...prevData, Amount: e.target.value }))} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Currency</label>
                                        <input type="text" className="form-control" value={formData.currency} onChange={(e) => setFormData(prevData => ({ ...prevData, currency: e.target.value }))} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Category</label>
                                        <select
                                            className="form-control"
                                            value={formData.ProjectID}
                                            onChange={(e) => setFormData(prevData => ({ ...prevData, ProjectID: e.target.value }))}
                                            required
                                        >
                                            <option value="">Select Category</option>
                                            {projects.map(project => (
                                                <option key={project.ProjectID} value={project.ProjectID}>
                                                    {project.ProjectName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowAddModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Add Contribution</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Contribution Modal */}
            {showEditModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowEditModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Contribution</h5>
                                <button type="button" className="close" style={{ float: 'right' }} onClick={() => setShowEditModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="modal-label">Member</label>
                                        {/* <input
                                            type="text"
                                            className="form-control mb-2"
                                            placeholder="Search by Name or Email"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        /> */}
                                        <select
                                            className="form-control"
                                            value={formData.MemberID}
                                            onChange={(e) => setFormData(prevData => ({ ...prevData, MemberID: e.target.value }))}
                                            required
                                        >
                                            <option value="">Select Member</option>
                                            {filteredMembers.map(member => (
                                                <option key={member.MemberID} value={member.MemberID}>
                                                    {member.Name} {member.Surname} {member.Email ? `(${member.Email})` : ''}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Date</label>
                                        <input type="date" className="form-control" value={formData.Date} onChange={(e) => setFormData(prevData => ({ ...prevData, Date: e.target.value }))} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Amount</label>
                                        <input type="number" className="form-control" value={formData.Amount} onChange={(e) => setFormData(prevData => ({ ...prevData, Amount: e.target.value }))} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Currency</label>
                                        <input type="text" className="form-control" value={formData.currency} onChange={(e) => setFormData(prevData => ({ ...prevData, currency: e.target.value }))} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Category</label>
                                        <select
                                            className="form-control"
                                            value={formData.ProjectID}
                                            onChange={(e) => setFormData(prevData => ({ ...prevData, ProjectID: e.target.value }))}
                                            required
                                        >
                                            <option value="">Select Category</option>
                                            {projects.map(project => (
                                                <option key={project.ProjectID} value={project.ProjectID}>
                                                    {project.ProjectName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update Contribution</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Contributions;

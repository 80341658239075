import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSort, faSortUp, faSortDown, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { API_URL } from "../config";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { getCurrentDate } from '../Components/DateFunction';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Ministries = () => {
    const [ministries, setMinistries] = useState([]);
    const [filteredMinistries, setFilteredMinistries] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [currentMinistryId, setCurrentMinistryId] = useState(null);
    const [formData, setFormData] = useState({
        Name: '',
        Description: '',
        LeaderID: null,
        StartDate: getCurrentDate(),
        EndDate: ''
    });
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [members, setMembers] = useState([]);
    const [memberSearchTerm, setMemberSearchTerm] = useState('');
    const [ministrySearchTerm, setMinistrySearchTerm] = useState('');
    const [totalMinistries, setTotalMinistries] = useState(0);
    const [sortField, setSortField] = useState('MinistryName');
    const [sortOrder, setSortOrder] = useState('asc');

    const navigate = useNavigate();

    const fetchMinistries = async () => {
        const response = await fetch(`${API_URL}/ministries/ministry-leaders`);
        const data = await response.json();
        setMinistries(data);
        setFilteredMinistries(data);
        setTotalMinistries(data.length);
    };

    const fetchAllMembers = async () => {
        const response = await fetch(`${API_URL}/members/all/Active`);
        const data = await response.json();
        setFilteredMembers(data);
        setMembers(data);
    };

    useEffect(() => {
        fetchMinistries();
        fetchAllMembers();
    }, []);

    //Modal logic
    useEffect(() => {
        const filtered = members.filter(member =>
            (member.Name || '').toLowerCase().includes(memberSearchTerm.toLowerCase()) ||
            (member.Surname || '').toLowerCase().includes(memberSearchTerm.toLowerCase()) ||
            (member.Email || '').toLowerCase().includes(memberSearchTerm.toLowerCase())
        );
        setFilteredMembers(filtered);
    }, [memberSearchTerm]);

    useEffect(() => {
        const filtered = ministries.filter(ministry =>
            (ministry.MinistryName || '').toLowerCase().includes(ministrySearchTerm.toLowerCase())
        );
        setFilteredMinistries(filtered);
        setTotalMinistries(filtered.length);
    }, [ministrySearchTerm, ministries]);   


    useEffect(() => {
        const filtered = members.filter(member =>
            (member.Name || '').toLowerCase().includes(memberSearchTerm.toLowerCase()) ||
            (member.Surname || '').toLowerCase().includes(memberSearchTerm.toLowerCase()) ||
            (member.Email || '').toLowerCase().includes(memberSearchTerm.toLowerCase())
        );
        setFilteredMembers(filtered);
    }, [memberSearchTerm]);

    const handleAddMinistry = () => {
        setFormData({ Name: '', Description: '', LeaderID: null, StartDate: '', EndDate: '' });
        setMemberSearchTerm('');
        setShowAddModal(true);
    };

    const handleEditMinistry = (ministry) => {
        setFormData({
            Name: ministry.MinistryName,
            Description: ministry.Description,
            LeaderID: ministry.LeaderID || null,
            StartDate: ministry.StartDate || '',
            EndDate: ministry.EndDate || ''
        });
        setCurrentMinistryId(ministry.MinistryID);
        setShowEditModal(true);
    };

    const handleViewMinistry = (ministryID) => {
        navigate(`/ministrymembers/${ministryID}`);
    };

    const handleSubmitAdd = async (e) => {
        e.preventDefault();
        try {
            const ministryResponse = await fetch(`${API_URL}/ministries`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    Name: formData.Name,
                    Description: formData.Description
                }),
            });

            if (!ministryResponse.ok) {
                const errorText = await ministryResponse.text();
                throw new Error(`Failed to create ministry: ${errorText}`);
            }

            const ministryData = await ministryResponse.json();
            if (ministryData.MinistryID) {
                const leaderResponse = await fetch(`${API_URL}/ministryleaders`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        MinistryID: ministryData.MinistryID,
                        LeaderID: formData.LeaderID,
                        StartDate: formData.StartDate,
                        EndDate: formData.EndDate
                    }),
                });

                if (!leaderResponse.ok) {
                    const errorText = await leaderResponse.text();
                    throw new Error(`Failed to add leader to ministry: ${errorText}`);
                }

                setMemberSearchTerm('');
                await fetchMinistries();
                setShowAddModal(false);
                Swal.fire({
                    text: "Successfully Added",
                    icon: "success"
                });
            } else {
                console.error("MinistryID not returned from the server");
                Swal.fire({
                    text: "Failed to create ministry: MinistryID not returned",
                    icon: "error"
                });
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                text: "Failed to add",
                icon: "error"
            });
        }
    };

    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_URL}/ministries/${currentMinistryId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    Name: formData.Name,
                    Description: formData.Description,
                    LeaderID: formData.LeaderID
                }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to update ministry: ${errorText}`);
            }

            await fetchMinistries();
            setMemberSearchTerm('');
            setShowEditModal(false);
            Swal.fire({
                text: "Successfully Updated",
                icon: "success"
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                text: "Failed to update",
                icon: "error"
            });
        }
    };


    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
        const sortedMinistries = [...filteredMinistries].sort((a, b) => {
            return order === 'asc' ? (a[field] > b[field] ? 1 : -1) : (a[field] < b[field] ? 1 : -1);
        });
        setFilteredMinistries(sortedMinistries);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(20);
        doc.text('New Life Covenant Church', 14, 22);
        doc.setFontSize(15);
        doc.text(`Ministries Report ${getCurrentDate()}`, 14, 35);
        doc.setFontSize(12);
        doc.text(`Total Ministries: ${filteredMinistries.length}`, 14, 52);

        autoTable(doc, {
            startY: 62,
            head: [['Name', 'Description', 'Leader']],
            body: filteredMinistries.map(ministry => [
                ministry.MinistryName,
                ministry.Description,
                `${ministry.LeaderName || 'N/A'} ${ministry.LeaderSurname || ''}`
            ]),
        });

        doc.save('ministries_report.pdf');
    };

    const generateCSV = () => {
        const headers = ['Name', 'Description', 'Leader'];
        const rows = filteredMinistries.map(ministry => [
            ministry.MinistryName,
            ministry.Description,
            `${ministry.LeaderName || 'N/A'} ${ministry.LeaderSurname || ''}`
        ]);

        const csvContent = [
            headers.join(','),
            ...rows.map(e => e.join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `ministries_report_${getCurrentDate()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Topnav />
                    <div className="container">
                        <div className="page-inner">
                            <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
                                <h2 className="text-capitalize font-weight-bold">
                                    Ministries ({totalMinistries})
                                </h2>
                                <div>
                                    <button className="btn btn-sm" onClick={generateCSV} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        Generate CSV
                                    </button> &nbsp;
                                    <button className="btn btn-sm" onClick={generatePDF} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        Generate PDF
                                    </button> &nbsp;
                                    <button className="btn btn-sm" onClick={handleAddMinistry} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        <FontAwesomeIcon icon={faPlus} /> Add Ministry
                                    </button>
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by Ministry Name"
                                    value={ministrySearchTerm}
                                    onChange={(e) => setMinistrySearchTerm(e.target.value)}
                                />
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th onClick={() => handleSort('MinistryName')} style={{ cursor: 'pointer' }}>
                                                Name {getSortIcon('MinistryName')}
                                            </th>
                                            <th>Description</th>
                                            <th>Leader</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredMinistries.map((ministry) => (
                                            <tr key={ministry.MinistryID}>
                                                <td>{ministry.MinistryName}</td>
                                                <td>{ministry.Description}</td>
                                                <td>{ministry.LeaderName ? `${ministry.LeaderName} ${ministry.LeaderSurname}` : 'N/A'}</td>
                                                <td>
                                                    <button onClick={() => handleEditMinistry(ministry)} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                    <button onClick={() => handleViewMinistry(ministry.MinistryID)} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <Footer />
                    </footer>
                </div>
            </div>

            {/* Add Ministry Modal */}
            {showAddModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowAddModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Add Ministry</h5>
                                <button type="button" className="close" onClick={() => setShowAddModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitAdd}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className='modal-label'>Name</label>
                                        <input type="text" className="form-control" value={formData.Name} onChange={(e) => setFormData({ ...formData, Name: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Description</label>
                                        <textarea className="form-control" value={formData.Description} onChange={(e) => setFormData({ ...formData, Description: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Search Leader</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search by Name, Surname, or Email"
                                            style={{ backgroundColor: '#c3f3f580' }}
                                            value={memberSearchTerm}
                                            onChange={(e) => setMemberSearchTerm(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Leader</label>
                                        <select
                                            className="form-control"
                                            value={formData.LeaderID}
                                            onChange={(e) => setFormData(prevData => ({ ...prevData, LeaderID: e.target.value }))}
                                            required
                                        >
                                            <option value="">Select Member</option>
                                            {filteredMembers.map(member => (
                                                <option key={member.MemberID} value={member.MemberID}>
                                                    {member.Name} {member.Surname} {member.Email ? `(${member.Email})` : ''}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowAddModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Add Ministry</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Ministry Modal */}
            {showEditModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowEditModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Ministry</h5>
                                <button type="button" className="close" onClick={() => setShowEditModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitEdit}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className='modal-label'>Name</label>
                                        <input type="text" className="form-control" value={formData.Name} onChange={(e) => setFormData({ ...formData, Name: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Description</label>
                                        <textarea className="form-control" value={formData.Description} onChange={(e) => setFormData({ ...formData, Description: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Search Leader</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search by Name, Surname, or Email"
                                            style={{ backgroundColor: '#c3f3f580' }}
                                            value={memberSearchTerm}
                                            onChange={(e) => setMemberSearchTerm(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Leader</label>
                                        <select
                                            className="form-control"
                                            value={formData.LeaderID}
                                            onChange={(e) => setFormData(prevData => ({ ...prevData, LeaderID: e.target.value }))}
                                            required
                                        // disabled
                                        >
                                            <option value="">Select Member</option>
                                            {filteredMembers.map(member => (
                                                <option key={member.MemberID} value={member.MemberID}>
                                                    {member.Name} {member.Surname} {member.Email ? `(${member.Email})` : ''}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update Ministry</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* View Ministry Modal */}
            {showViewModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowViewModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">View Ministry</h5>
                                <button type="button" className="close" onClick={() => setShowViewModal(false)}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" className="form-control" value={formData.Name} readOnly />
                                </div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea className="form-control" value={formData.Description} readOnly />
                                </div>
                                <div className="form-group">
                                    <label>Leader</label>
                                    <input type="text" className="form-control" value={`${formData.LeaderID}`} readOnly />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowViewModal(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Ministries;
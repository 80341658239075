import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { API_URL } from "../config";
import { getCurrentDate } from '../Components/DateFunction';
import { getCurrentTime } from '../Components/TimeFunction';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Swal from 'sweetalert2';

const PrayerReq = () => {
    const [prayer_reqs, setPrayeReqs] = useState([]);
    const [filteredPrayerReqs, setFilteredPrayerReqs] = useState([]);
    const [filteredMembers2, setFilteredMembers2] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentPrayerReqId, setCurrentPrayerReqId] = useState(null);
    const [formData, setFormData] = useState({ MemberID: '', requestnotes: '' });
    const [searchTerm, setSearchTerm] = useState('');
    const [allMembers, setAllMembers] = useState([]);

    const [nameSurnameSearchTerm, setNameSurnameSearchTerm] = useState('');

    // const [sortField, setSortField] = useState('Name');
    // const [sortOrder, setSortOrder] = useState('asc');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // ... existing fetch functions and useEffect hooks

    const filterByDateRange = () => {
        const filtered = prayer_reqs.filter(prayer_req => {
            const requestedDate = new Date(prayer_req.requestedon);
            const start = new Date(startDate);
            const end = new Date(endDate);
            return (!startDate || requestedDate >= start) && (!endDate || requestedDate <= end);
        });
        setFilteredPrayerReqs(filtered);
    };

    useEffect(() => {
        const filtered = prayer_reqs.filter(prayer_req =>
            (prayer_req.name ? prayer_req.name.toLowerCase() : '').includes(nameSurnameSearchTerm.toLowerCase()) ||
            (prayer_req.surname ? prayer_req.surname.toLowerCase() : '').includes(nameSurnameSearchTerm.toLowerCase())
        );
        setFilteredPrayerReqs(filtered);
    }, [nameSurnameSearchTerm, prayer_reqs]);


    useEffect(() => {
        filterByDateRange();
    }, [startDate, endDate, prayer_reqs]);

    const fetchPrayerReq = async () => {
        try {
            const response = await fetch(`${API_URL}/prayer-req`);
            if (!response.ok) throw new Error('Failed to fetch Prayer Requests');
            const data = await response.json();
            setPrayeReqs(data);
            setFilteredPrayerReqs(data);

        } catch (error) {
            console.error(error);
            alert('Error fetching Prayer Requests: ' + error.message);
        }
    };

    const fetchAllMembers = async () => {
        const response = await fetch(`${API_URL}/members/all/Active`);
        const data = await response.json();
        setAllMembers(data);
        setFilteredMembers2(data);
    };

    useEffect(() => {
        fetchPrayerReq();
        fetchAllMembers();
    }, []);

    useEffect(() => {
        const filtered = prayer_reqs.filter(prayer_req =>
            (prayer_req.header ? prayer_req.header.toLowerCase() : '').includes(searchTerm.toLowerCase())
        );
        setFilteredPrayerReqs(filtered);
    }, [searchTerm, prayer_reqs]);

    useEffect(() => {
        const filtered = allMembers.filter(Members =>
            (Members.Name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (Members.Surname || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (Members.Email || '').toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredMembers2(filtered);
    }, [searchTerm]);

    const handleAddPrayerReq = () => {
        setFormData({ header: '', content: '', date: getCurrentDate(), time: getCurrentTime(), MemberID: '100' });
        setShowAddModal(true);
    };

    const handleEditPrayerReq = (prayer_req) => {
        setFormData(prayer_req);
        setCurrentPrayerReqId(prayer_req.id);
        setShowEditModal(true);
    };

    // const handleDeletePrayerReq = async (id) => {
    //     if (window.confirm("Are you sure you want to delete this prayer_req?")) {
    //         await fetch(`${API_URL}/prayer_req/${id}`, { method: 'DELETE' });
    //         fetchPrayerReq();
    //     }
    // };

    const handleDeletePrayerReq = async (id) => {
        if (window.confirm("Are you sure you want to delete this prayer_req?")) {
            try {
                const response = await fetch(`${API_URL}/prayer_req/${id}`, { method: 'DELETE' });
                if (!response.ok) throw new Error('Failed to delete Prayer Request');
                await fetchPrayerReq();
                Swal.fire('Deleted!', 'Prayer Request has been deleted.', 'success');
            } catch (error) {
                console.error(error);
                Swal.fire({
                    text: "Failed to delete",
                    icon: "error"
                });
            }
        }
    };


    const handleSubmitAdd = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_URL}/prayer-req`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to add Prayer Request: ${errorText}`);
            }

            await fetchPrayerReq();
            setShowAddModal(false);
            Swal.fire({
                text: "Succefully Added",
                icon: "success"
            });
            fetchPrayerReq();
        } catch (error) {
            console.error(error);
            Swal.fire({
                text: "Failed to add",
                icon: "error"
            });
        }
    };


    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        console.log("Updating group with ID:", currentPrayerReqId);
        console.log("Form Data:", formData); // Log form data

        try {
            const response = await fetch(`${API_URL}/prayer-req/${formData.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const errorText = await response.text(); // Get error text for better debugging
                throw new Error(`Failed to update the Prayer Request: ${errorText}`);
            }

            fetchPrayerReq();
            setShowEditModal(false);
        } catch (error) {
            console.error(error);
            alert('Error updating Prayer Request: ' + error.message);
        }
    };

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(20);
        doc.text('New Life Covenant Church', 14, 22);
        doc.setFontSize(15);
        doc.text(`Prayer Request Report ${getCurrentDate()}`, 14, 35);
        doc.setFontSize(12);
        doc.text(`Total Prayer Requests: ${filteredPrayerReqs.length}`, 14, 52);

        autoTable(doc, {
            startY: 62,
            head: [['Name', 'Surname', 'Date', 'Notes']],
            body: filteredPrayerReqs.map(prayer_req => [
                prayer_req.name,
                prayer_req.surname,
                prayer_req.requestedon.slice(0, 10),
                prayer_req.requestnotes
            ]),
        });

        doc.save('prayer_requests_report.pdf');
    };

    const generateCSV = () => {
        const headers = ['Name', 'Surname', 'Date', 'Notes'];
        const rows = filteredPrayerReqs.map(prayer_req => [
            prayer_req.name,
            prayer_req.surname,
            prayer_req.requestedon.slice(0, 10),
            prayer_req.requestnotes
        ]);

        const csvContent = [
            headers.join(','),
            ...rows.map(e => e.join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `prayer_requests_report_${getCurrentDate()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <div>
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Topnav />
                    <div className="container">
                        <div className="page-inner">
                            <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
                                <h2 className="text-capitalize font-weight-bold">
                                    Prayer Requests ({filteredPrayerReqs.length})
                                </h2>
                                <div>
                                    <button className="btn btn-sm" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={generateCSV}>
                                        Generate CSV
                                    </button> &nbsp;
                                    <button className="btn btn-sm" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={generatePDF}>
                                        Generate PDF
                                    </button> &nbsp;
                                    <button className="btn btn-sm" onClick={handleAddPrayerReq} style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        <FontAwesomeIcon icon={faPlus} /> Add Prayer Request
                                    </button>
                                </div>
                            </div>
                            <div className="d-flex mb-3 w-100">
                                <div className="form-group mb-4" style={{ flex: '0 0 65%' }}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by Name or Surname"
                                        value={nameSurnameSearchTerm}
                                        onChange={(e) => setNameSurnameSearchTerm(e.target.value)}
                                    />
                                </div>
                                <div className="form-group flex-fill" style={{ flex: '0 0 17.5%' }}>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                </div>
                                <div className="form-group flex-fill" style={{ flex: '0 0 17.5%' }}>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            {/* <th>MemberID</th> */}
                                            <th>Name</th>
                                            <th>Surname</th>
                                            <th>Date</th>
                                            <th>Request Notes</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredPrayerReqs.map((prayer_req) => (
                                            <tr key={prayer_req.id}>
                                                {/* <td>{prayer_req.MemberID}</td> */}
                                                <td>{prayer_req.name}</td>
                                                <td>{prayer_req.surname}</td>
                                                <td>{prayer_req.requestedon.slice(0, 10)}</td>
                                                <td>{prayer_req.requestnotes}</td>
                                                <td>
                                                    <button onClick={() => handleEditPrayerReq(prayer_req)} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                    {/* <button onClick={() => handleDeletePrayerReq(prayer_req.id)} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <Footer />
                    </footer>
                </div>
            </div>

            {/* Add Prayer Request Modal */}
            {showAddModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowAddModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Add Prayer Request</h5>
                                <button type="button" className="close" onClick={() => setShowAddModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitAdd}>
                                <div className="modal-body">
                                    {/* <div className="form-group">
                                        <label>Member</label>
                                        <input type="text" className="form-control" value={formData.MemberID} onChange={(e) => setFormData({ ...formData, MemberID: e.target.value })} required />
                                    </div> */}
                                    <div className="form-group">
                                        <label>Member</label>
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            placeholder="Search by Name or Email"
                                            style={{ backgroundColor: '#c3f3f580' }}
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <select
                                            className="form-control"
                                            value={formData.MemberID}
                                            onChange={(e) => setFormData(prevData => ({ ...prevData, MemberID: e.target.value }))}
                                            required
                                        >
                                            <option value="">Select Member</option>
                                            {filteredMembers2.map(member => (
                                                <option key={member.MemberID} value={member.MemberID}>
                                                    {member.Name} {member.Surname} {member.Email ? `(${member.Email})` : ''}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Notes</label>
                                        <input type="text" className="form-control" value={formData.requestnotes} onChange={(e) => setFormData({ ...formData, requestnotes: e.target.value })} required />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowAddModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Add Prayer Request</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Prayer Request Modal */}
            {showEditModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowEditModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Prayer Request</h5>
                                <button type="button" className="close" onClick={() => setShowEditModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitEdit}>
                                <div className="modal-body">
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <label>Member</label>
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                placeholder="Search by Name or Email"
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                            <select
                                                className="form-control"
                                                value={formData.MemberID}
                                                onChange={(e) => setFormData(prevData => ({ ...prevData, MemberID: e.target.value }))}
                                                required
                                            >
                                                <option value="">Select Member</option>
                                                {filteredMembers2.map(member => (
                                                    <option key={member.MemberID} value={member.MemberID}>
                                                        {member.Name} {member.Surname} {member.Email ? `(${member.Email})` : ''}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Notes</label>
                                            <input type="text" className="form-control" value={formData.requestnotes} onChange={(e) => setFormData({ ...formData, requestnotes: e.target.value })} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update Prayer Request</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PrayerReq;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSort, faSortUp, faSortDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { API_URL } from "../config";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { getCurrentDate } from '../Components/DateFunction';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const MinistryMembers = () => {
    const { id } = useParams();

    const [ministries, setMembers] = useState([]);
    const [ministryMembers, setMinistryMembers] = useState([]);
    const [ministryName, setMinistryName] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [filteredMembers2, setFilteredMembers2] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [formData2, setFormData2] = useState({
        MemberID: '',
        MinistryID: id,
        Request: 'Approved'
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [totalMembers, setTotalMembers] = useState(0);
    const [sortField, setSortField] = useState('MembersName');
    const [sortOrder, setSortOrder] = useState('asc');

    const navigate = useNavigate();


    useEffect(()=>{
        fetchAllMembers();
    },[])
    const fetchAllMembers = async () => {
        const response = await fetch(`${API_URL}/members/all/Active`);
        const data = await response.json();
        setMinistryMembers(data);
        setFilteredMembers2(data);
    };

    const fetchMembers = async () => {
        const response = await fetch(`${API_URL}/ministrymembers/join/${id}`);
        const data = await response.json();
        setMembers(data);
        setFilteredMembers(data);
        setTotalMembers(data.length);
    };      

    const fetchMinistryName = async () => {
        const response = await fetch(`${API_URL}/ministries/${id}`);
        const data = await response.json();
        setMinistryName(data[0].Name);
    };

    useEffect(() => {
        const callFunctions = async () =>{
            fetchMinistryName();
            fetchMembers();
        }

        callFunctions();

    }, []);

    useEffect(() => {
        const filtered = ministries.filter(Members =>
            (Members.Name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (Members.Surname || '').toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredMembers(filtered);
        setTotalMembers(filtered.length);
    }, [searchTerm, ministries]);
    
    useEffect(() => {
        const filtered = ministryMembers.filter(Members =>
            (Members.Name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (Members.Surname || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (Members.Email || '').toLowerCase().includes(searchTerm.toLowerCase()) 
        );
        setFilteredMembers2(filtered);
    }, [searchTerm, ministries]);

    const handleAddMembers = () => {
        // setFormData({ Name: '', Surname: '', Email: null, Phone: '', Address: '' });
        setShowAddModal(true);
    };


    // const handleDeleteMembers = async (memberId) => {
    //     if (window.confirm("Are you sure you want to delete this Members?")) {
    //         await fetch(`${API_URL}/ministrymembers/${memberId}/${id}`, {
    //             method: 'DELETE',
    //         });
    //         fetchMembers(); 
    //     }
    // };

    const handleDeleteMembers = async (memberId) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });
    
        if (result.isConfirmed) {
            try {
                await fetch(`${API_URL}/ministrymembers/${memberId}/${id}`, {
                    method: 'DELETE',
                });
                fetchMembers();
                Swal.fire({
                    text: "Member successfully deleted",
                    icon: "success"
                });
            } catch (error) {
                console.error(error);
                Swal.fire({
                    text: "Failed to delete member",
                    icon: "error"
                });
            }
        } else {
            Swal.fire({
                text: "Deletion canceled",
                icon: "info"
            });
        }
    };
    

    // const handleSubmitAdd = async (e) => {
    //     e.preventDefault();

    //     // Add the Members
    //     await fetch(`${API_URL}/ministrymembers`, {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({
    //             MemberID: formData2.MemberID,
    //             MinistryID: formData2.MinistryID,
    //             Request: formData2.Request
    //         }),
    //     }); 
    //     setShowAddModal(false);
    //     fetchMembers();
    // };

    const handleSubmitAdd = async (e) => {
        e.preventDefault();
        try {
            // Add the Members
            const response = await fetch(`${API_URL}/ministrymembers`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    MemberID: formData2.MemberID,
                    MinistryID: formData2.MinistryID,
                    Request: formData2.Request
                }),
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to add member: ${errorText}`);
            }
    
            setShowAddModal(false);
            setFilteredMembers2('');
            await fetchMembers();
            Swal.fire({
                text: "Successfully Added Member",
                icon: "success"
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                text: "Failed to add member",
                icon: "error"
            });
        }
    };
    

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
        const sortedMembers = [...filteredMembers].sort((a, b) => {
            return order === 'asc' ? (a[field] > b[field] ? 1 : -1) : (a[field] < b[field] ? 1 : -1);
        });
        setFilteredMembers(sortedMembers);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    const handleViewMinistryLeaders = (ministryID) => {
        navigate(`/ministryleaders/${ministryID}`)
    };

    const generatePDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(20);
        doc.text('New Life Covenant Church', 14, 22);
        doc.setFontSize(15);
        doc.text(`${ministryName} Members Report ${getCurrentDate()}`, 14, 35);
        doc.setFontSize(12);
        doc.text(`Total Members: ${filteredMembers.length}`, 14, 52);

        autoTable(doc, {
            startY: 62,
            head: [['Name', 'Surname', 'Email', 'Phone', 'Address']],
            body: filteredMembers.map(Members => [
                Members.Name,
                Members.Surname,
                Members.Email,
                Members.Phone,
                Members.Address,
            ]),
        });

        doc.save(`${ministryName}_members_report.pdf`);
    };

    const generateCSV = () => {
        const csvRows = [];
        
        // Add header row
        csvRows.push(['Name', 'Surname', 'Email', 'Phone', 'Address'].join(','));
        
        // Add data rows
        filteredMembers.forEach(member => {
            const row = [
                member.Name,
                member.Surname,
                member.Email,
                member.Phone,
                member.Address,
            ];
            csvRows.push(row.join(','));
        });
        
        // Create a CSV string
        const csvString = csvRows.join('\n');
        
        // Create a Blob from the CSV string
        const blob = new Blob([csvString], { type: 'text/csv' });
        
        // Create a link element to download the CSV
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${ministryName}_members_report.csv`; 
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url); 
    };
    

    return (
        <div>
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Topnav />
                    <div className="container">
                        <div className="page-inner">
                            <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
                                <h2 className="text-capitalize font-weight-bold">
                                    {ministryName} Members ({totalMembers})
                                </h2>
                                <div>
                                    <button className="btn btn-sm" onClick={generateCSV} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        Generate CSV
                                    </button> &nbsp;
                                    <button className="btn btn-sm" onClick={generatePDF} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        Generate PDF
                                    </button> &nbsp;
                                    <button className="btn btn-sm" onClick={() => handleViewMinistryLeaders(id)} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        View Leaders
                                    </button> &nbsp;
                                    <button className="btn btn-sm" onClick={handleAddMembers} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        <FontAwesomeIcon icon={faPlus} /> Add Members
                                    </button>
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by Members Name"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th onClick={() => handleSort('MembersName')} style={{ cursor: 'pointer' }}>
                                                Name {getSortIcon('MembersName')}
                                            </th>
                                            <th>Surname</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Address</th>
                                            <th>Zone</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredMembers.map((Members) => (
                                            <tr key={Members.MemberMinistryID}>
                                                <td>{Members.Name}</td>
                                                <td>{Members.Surname}</td>
                                                <td>{Members.Email}</td>
                                                <td>{Members.Phone}</td>
                                                <td>{Members.Address}</td>
                                                <td>{Members.Zone}</td>
                                                <td>
                                                    <button onClick={() => handleDeleteMembers(Members.MemberID)} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <Footer />
                    </footer>
                </div>
            </div>

            {/* Add Members Modal */}
            {showAddModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowAddModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Members</h5>
                                <button type="button" className="close" onClick={() => setShowAddModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitAdd}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="modal-label">Member</label>
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            placeholder="Search by Name or Email"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <select
                                            className="form-control"
                                            value={formData2.MemberID}
                                            onChange={(e) => setFormData2(prevData => ({ ...prevData, MemberID: e.target.value }))}
                                            required
                                        >
                                            <option value="">Select Member</option>
                                            {filteredMembers2.map(member => (
                                                <option key={member.MemberID} value={member.MemberID}>
                                                    {member.Name} {member.Surname} {member.Email ? `(${member.Email})` : ''}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowAddModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Add Members</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MinistryMembers;

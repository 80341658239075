import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { API_URL } from "../config";
import Swal from 'sweetalert2';

const Users = () => {
    // const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [formData, setFormData] = useState({
        user_id: '',
        user_name: '',
        surname: '',
        email: '',
        role: '',
        phone: '',
        address: ''
    });
    const [totalUsers, setTotalUsers] = useState(0);

    const fetchUsers = async () => {
        const response = await fetch(`${API_URL}/users`);
        const data = await response.json();
        // setUsers(data);
        setFilteredUsers(data);
        setTotalUsers(data.length);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleAddUser = () => {
        setFormData({
            user_id: '',
            user_name: '',
            surname: '',
            email: '',
            role: '',
            phone: '',
            address: ''
        });
        setShowAddModal(true);
    };

    const handleEditUser = (user) => {
        setFormData(user);
        setCurrentUserId(user.user_id);
        setShowEditModal(true);
    };

    // const handleDeleteUser = async (id) => {
    //     if (window.confirm("Are you sure you want to delete this user?")) {
    //         await fetch(`${API_URL}/users/${id}`, {
    //             method: 'DELETE',
    //         });
    //         fetchUsers();
    //     }
    // };

    const handleDeleteUser = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });
        
        if (result.isConfirmed) {
            try {
                const response = await fetch(`${API_URL}/users/${id}`, {
                    method: 'DELETE',
                });
    
                if (!response.ok) {
                    throw new Error('Failed to delete user'); // Throw an error if the response is not OK
                }
    
                // Alert user on success
                Swal.fire({
                    text: "User deleted successfully!",
                    icon: "success"
                });
    
                fetchUsers();
            } catch (error) {
                console.error("Error deleting user:", error);
                Swal.fire({
                    text: "An error occurred while deleting the user.",
                    icon: "error"
                });
            }
        }
    };
    

    // const handleSubmitAdd = async (e) => {
    //     e.preventDefault();
    //     await fetch(`${API_URL}/users`, {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify(formData),
    //     });
    //     fetchUsers();
    //     setShowAddModal(false);
    // };

    const handleSubmitAdd = async (e) => {
        e.preventDefault();
        
        try {
            const response = await fetch(`${API_URL}/users`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
    
            if (!response.ok) {
                throw new Error('Failed to add user'); 
            }
    
            // Alert user on success
            Swal.fire({
                text: "User added successfully!",
                icon: "success"
            });
    
            fetchUsers();
            setShowAddModal(false);
        } catch (error) {
            console.error("Error adding user:", error);
            Swal.fire({
                text: "An error occurred while adding the user.",
                icon: "error"
            });
        }
    };
    

    // const handleSubmitEdit = async (e) => {
    //     e.preventDefault();
    //     await fetch(`${API_URL}/users/${currentUserId}`, {
    //         method: 'PUT',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify(formData),
    //     });
    //     fetchUsers();
    //     setShowEditModal(false);
    // };

    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        
        try {
            const response = await fetch(`${API_URL}/users/${currentUserId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
    
            if (!response.ok) {
                throw new Error('Failed to update user'); 
            }

            if (response.ok) {
                Swal.fire({
                    text: "Successfully updated user info.",
                    icon: "success"
                });
            }
            
    
            fetchUsers();
            setShowEditModal(false);
        } catch (error) {
            console.error("Error updating user:", error);
            Swal.fire({
                text: "An error occurred while updating the user.",
                icon: "error"
            });
        }
    };
    

    return (
        <div>
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Topnav />
                    <div className="container">
                        <div className="page-inner">
                            <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
                                <h2 className="text-capitalize font-weight-bold">
                                    Users ({totalUsers})
                                </h2>
                                <div>
                                    <button className="btn btn-sm" onClick={handleAddUser} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        <FontAwesomeIcon icon={faPlus} /> Add User
                                    </button>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Surname</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Address</th>
                                            {/* <th>Role</th> */}
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredUsers.map((user, index) => (
                                            <tr key={user.user_id}>
                                                <td>{user.user_name}</td>
                                                <td>{user.surname}</td>
                                                <td>{user.email}</td>
                                                <td>{user.phone}</td>
                                                {/* <td>{user.role}</td> */}
                                                <td>{user.address}</td>
                                                <td>
                                                    <button onClick={() => handleEditUser(user)} className="btn btn-link mr-2">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                    <button onClick={() => handleDeleteUser(user.user_id)} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <Footer />
                    </footer>
                </div>
            </div>

            {/* Add User Modal */}
            {showAddModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowAddModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Add User</h5>
                                <button type="button" className="close" onClick={() => setShowAddModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitAdd}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className='modal-label'>Name</label>
                                        <input type="text" className="form-control" value={formData.user_name} onChange={(e) => setFormData({ ...formData, user_name: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Surname</label>
                                        <input type="text" className="form-control" value={formData.surname} onChange={(e) => setFormData({ ...formData, surname: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Email</label>
                                        <input type="text" className="form-control" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Phone</label>
                                        <input type="text" className="form-control" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Address</label>
                                        <textarea type="text" className="form-control" value={formData.address} onChange={(e) => setFormData({ ...formData, address: e.target.value })} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowAddModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Add User</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit User Modal */}
            {showEditModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowEditModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content"  style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Edit User</h5>
                                <button type="button" className="close" onClick={() => setShowEditModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitEdit}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className='modal-label'>Name</label>
                                        <input type="text" className="form-control" value={formData.user_name} onChange={(e) => setFormData({ ...formData, user_name: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Surname</label>
                                        <input type="text" className="form-control" value={formData.surname} onChange={(e) => setFormData({ ...formData, surname: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Email</label>
                                        <input type="text" className="form-control" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Phone</label>
                                        <input type="text" className="form-control" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Address</label>
                                        <textarea type="text" className="form-control" value={formData.address} onChange={(e) => setFormData({ ...formData, address: e.target.value })} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update User</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Users; 

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSort, faSortUp, faSortDown, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { API_URL } from "../config";
import { getCurrentDate } from '../Components/DateFunction';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Swal from 'sweetalert2';

const Children = () => {
    const [children, setChildren] = useState([]);
    const [members, setMembers] = useState([]);
    const [filteredChildren, setFilteredChildren] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentChildId, setCurrentChildId] = useState(null);
    const [formData, setFormData] = useState({
        childID: '',
        parentID: '',
        name: '',
        surname: '',
        relationship: '',
        gender: '',
        dob: ''
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTerm2, setSearchTerm2] = useState('');
    const [startDateFilter, setStartDateFilter] = useState('');
    const [totalChildren, setTotalChildren] = useState(0);
    const [sortField, setSortField] = useState('Date');
    const [sortOrder, setSortOrder] = useState('asc');

    const [filteredMembers, setFilteredMembers] = useState([]);

    const fetchChildren = async () => {
        const response = await fetch(`${API_URL}/children`);
        const data = await response.json();
        setChildren(data);
        setFilteredChildren(data);
        setTotalChildren(data.length);
    };

    useEffect(() => {
        fetchChildren();
        fetchMembers();
    }, []);

    const fetchMembers = async () => {
        const response = await fetch(`${API_URL}/members/all/Active`);
        const data = await response.json();
        setMembers(data);
        setFilteredMembers(data);
    };

    useEffect(() => {
        const matches = members.filter(member =>
            member.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (member.Email && member.Email.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (member.Surname && member.Surname.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        setFilteredMembers(matches);

        if (matches.length === 1) {
            setFormData(prevData => ({ ...prevData, MemberID: matches[0].MemberID }));
        } else {
            if (matches.length === 0 || matches.length > 1) {
                setFormData(prevData => ({ ...prevData, MemberID: '' }));
            }
        }
    }, [searchTerm, members]);

    useEffect(() => {
        const filtered = children.filter(child =>
            (child.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                child.surname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                getMemberName(child.parentID).toLowerCase().includes(searchTerm.toLowerCase())) && // Filter by parent/guardian name
            (!startDateFilter || new Date(child.dob) >= new Date(startDateFilter))
        );
        setFilteredChildren(filtered);
        setTotalChildren(filtered.length);
    }, [searchTerm, startDateFilter, children]);

    useEffect(() => {
        const matches = members.filter(member =>
            member.Name.toLowerCase().includes(searchTerm2.toLowerCase()) ||
            (member.Email && member.Email.toLowerCase().includes(searchTerm2.toLowerCase())) ||
            (member.Surname && member.Surname.toLowerCase().includes(searchTerm2.toLowerCase()))
        );
        setFilteredMembers(matches);

        if (matches.length === 1) {
            setFormData(prevData => ({ ...prevData, MemberID: matches[0].MemberID }));
        } else {
            if (matches.length === 0 || matches.length > 1) {
                setFormData(prevData => ({ ...prevData, MemberID: '' }));
            }
        }
    }, [searchTerm2, members]);

    useEffect(() => {
        const filtered = children.filter(child =>
            (child.name.toLowerCase().includes(searchTerm2.toLowerCase()) ||
                child.surname.toLowerCase().includes(searchTerm2.toLowerCase()) ||
                getMemberName(child.parentID).toLowerCase().includes(searchTerm2.toLowerCase())) && // Filter by parent/guardian name
            (!startDateFilter || new Date(child.dob) >= new Date(startDateFilter))
        );
        setFilteredChildren(filtered);
        setTotalChildren(filtered.length);
    }, [searchTerm2, startDateFilter, children]);


    const handleAddChild = () => {
        setFormData({
            childID: '',
            parentID: '',
            name: '',
            surname: '',
            relationship: '',
            gender: '',
            dob: ''
        });
        setShowAddModal(true);
    };

    const handleEditChild = (child) => {
        setFormData(child);
        setCurrentChildId(child.childID);
        setShowEditModal(true);
    };

    const handleDeleteChild = async (id) => {

        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });

        if (result.isConfirmed) {
            try {
                await fetch(`${API_URL}/children/${id}`, {
                    method: 'DELETE',
                });
                fetchChildren();
                Swal.fire({
                    text: "Deleted Successfully!",
                    icon: "success"
                });
            } catch (error) {
                Swal.fire({
                    text: "An error occurred while deleting!",
                    icon: "error"
                });
            }
        }
    };

    const handleSubmitAdd = async (e) => {

        e.preventDefault();
        try {
            let response;

            // Add new contribution
            response = await fetch(`${API_URL}/children`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            if (!response.ok) throw new Error('Addition failed');
            // setShowAddModal(false);
            Swal.fire({
                text: "Added Successfully!",
                icon: "success"
            });
            fetchChildren();
            setShowAddModal(false);
        } catch (error) {
            Swal.fire({
                text: error.message || "An error occurred!",
                icon: "error"
            });
        }

    };
    const handleSubmitEdit = async (e) => {

        let response;
        try {
            // Update existing contribution
            response = await fetch(`${API_URL}/children/${currentChildId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            if (!response.ok) throw new Error('Update failed');
            setShowEditModal(false);
            Swal.fire({
                text: "Updated Successfully!",
                icon: "success"
            });
            setShowAddModal(false);
            fetchChildren();
        } catch (error) {
            Swal.fire({
                text: error.message || "An error occurred!",
                icon: "error"
            });
        }
    }   

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
        const sortedChildren = [...filteredChildren].sort((a, b) => {
            return order === 'asc' ? (a[field] > b[field] ? 1 : -1) : (a[field] < b[field] ? 1 : -1);
        });
        setFilteredChildren(sortedChildren);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    // Function to get member name by ID
    const getMemberName = (memberID) => {
        const member = members.find(m => m.MemberID === memberID);
        return member ? `${member.Name} ${member.Surname}` : 'Unknown';
    };

    const generatePDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(20);
        doc.text('New Life Covenant Church', 14, 22);
        doc.setFontSize(15);
        doc.text(`Children Report ${getCurrentDate()}`, 14, 35);
        doc.setFontSize(12);
        doc.text(`Total Children: ${filteredChildren.length}`, 14, 52);

        autoTable(doc, {
            startY: 62,
            head: [['Name', 'Surname', 'DOB', 'Parent/Guardian', 'Relationship']],
            body: filteredChildren.map(child => [
                child.name,
                child.surname,
                child.dob.slice(0, 10),
                getMemberName(child.parentID),
                child.relationship
            ]),
        });

        doc.save('Children_report.pdf');
    };

    const generateCSV = () => {
        const headers = ['Name', 'Surname', 'DOB', 'Parent/Guardian', 'Relationship'];
        const rows = filteredChildren.map(child => [
            child.name,
            child.surname,
            child.dob.slice(0, 10),
            getMemberName(child.parentID),
            child.relationship
        ]);

        // Create a CSV string
        const csvContent = [
            headers.join(','),
            ...rows.map(e => e.join(','))
        ].join('\n');

        // Create a blob from the CSV string
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        // Create a link element and trigger a download
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `Children_report_${getCurrentDate()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <div>
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Topnav />
                    <div className="container">
                        <div className="page-inner">
                            <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
                                <h2 className="text-capitalize font-weight-bold">
                                    Children ({totalChildren})
                                </h2>
                                <div>
                                    <button className="btn btn-sm" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={generateCSV}>
                                        Generate CSV
                                    </button> &nbsp;
                                    <button className="btn btn-sm" onClick={generatePDF} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        <FontAwesomeIcon icon={faPlus} /> Generate PDF
                                    </button> &nbsp;
                                    <button className="btn btn-sm" onClick={handleAddChild} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        <FontAwesomeIcon icon={faPlus} /> Add Child
                                    </button>
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <div className="d-flex">
                                    <input
                                        type="text"
                                        className="form-control me-2"
                                        placeholder="Search by Child Name, Surname, or Parent"
                                        value={searchTerm2}
                                        onChange={(e) => setSearchTerm2(e.target.value)}
                                        style={{ flex: '0 0 100%' }}
                                    />
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Surname</th>
                                            <th>D.O.B</th>
                                            <th>Parent/Guardian</th>
                                            <th>Relationship</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredChildren.map((child, index) => (
                                            <tr key={index}>
                                                <td>{child.name}</td>
                                                <td>{child.surname}</td>
                                                {/* <td>{child.dob.slice(0, 10)}</td> */}
                                                <td>{child.dob ? child.dob.slice(0, 10) : ''}</td>
                                                <td>{getMemberName(child.parentID)}</td>
                                                <td>{child.relationship}</td>
                                                <td>
                                                    <button onClick={() => handleEditChild(child)} className="btn btn-link mr-2">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                    <button onClick={() => handleDeleteChild(child.childID)} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>   
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <Footer />
                    </footer>
                </div>
            </div>

            {/* Add Child Modal */}
            {showAddModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowAddModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Add Child</h5>
                                <button type="button" className="close" onClick={() => setShowAddModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitAdd}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className='modal-label'>Name</label>
                                        <input type="text" className="form-control" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Surname</label>
                                        <input type="text" className="form-control" value={formData.surname} onChange={(e) => setFormData({ ...formData, surname: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Gender</label>
                                        <select
                                            className="form-control"
                                            id="gender"
                                            name="gender"
                                            value={formData.gender}
                                            onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                                            required
                                        >
                                            <option value="">Select Gender</option>
                                            <option>Male</option>
                                            <option>Female</option>

                                        </select>
                                    </div>
                                    {/* <div className="form-group">
                                        <label className='modal-label'>Gender</label>
                                        <input type="text" className="form-control" value={formData.gender} onChange={(e) => setFormData({ ...formData, gender: e.target.value })} required />
                                    </div> */}
                                    <div className="form-group">
                                        <label className='modal-label'>Date of Birth</label>
                                        <input type="date" className="form-control" value={formData.dob.slice(0, 10)} onChange={(e) => setFormData({ ...formData, dob: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Parent / Guardian</label>
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            placeholder="Search by Name or Email"
                                            style={{ backgroundColor: '#c3f3f580' }}
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <select
                                            className="form-control"
                                            value={formData.parentID}
                                            onChange={(e) => setFormData(prevData => ({ ...prevData, parentID: e.target.value }))}
                                            required
                                        >
                                            <option value="">Select Member</option>
                                            {filteredMembers.map(member => (
                                                <option key={member.MemberID} value={member.MemberID}>
                                                    {member.Name} {member.Surname} {member.Email ? `(${member.Email})` : ''}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Relationship</label>
                                        <input type="text" className="form-control" value={formData.relationship} onChange={(e) => setFormData({ ...formData, relationship: e.target.value })} required />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowAddModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Add Child</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Child Modal */}
            {showEditModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowEditModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Child</h5>
                                <button type="button" className="close" onClick={() => setShowEditModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitEdit}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className='modal-label'>Name</label>
                                        <input type="text" className="form-control" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Surname</label>
                                        <input type="text" className="form-control" value={formData.surname} onChange={(e) => setFormData({ ...formData, surname: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Gender</label>
                                        <select
                                            className="form-control"
                                            id="gender"
                                            name="gender"
                                            value={formData.gender}
                                            onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                                            required
                                        >
                                            <option value="">Select Gender</option>
                                            <option>Male</option>
                                            <option>Female</option>

                                        </select>
                                    </div>
                                    {/* <div className="form-group">
                                        <label className='modal-label'>Gender</label>
                                        <input type="text" className="form-control" value={formData.gender} onChange={(e) => setFormData({ ...formData, gender: e.target.value })} required />
                                    </div> */}
                                    <div className="form-group">
                                        <label className='modal-label'>Date of Birth</label>
                                        <input type="date" className="form-control" value={formData.dob} onChange={(e) => setFormData({ ...formData, dob: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Parent / Guardian</label>
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            placeholder="Search by Name or Email"
                                            style={{ backgroundColor: '#c3f3f580' }}
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <select
                                            className="form-control"
                                            value={formData.parentID}
                                            onChange={(e) => setFormData(prevData => ({ ...prevData, parentID: e.target.value }))}
                                            required
                                        >
                                            <option value="">Select Member</option>
                                            {filteredMembers.map(member => (
                                                <option key={member.MemberID} value={member.MemberID}>
                                                    {member.Name} {member.Surname} {member.Email ? `(${member.Email})` : ''}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Relationship</label>
                                        <input type="text" className="form-control" value={formData.relationship} onChange={(e) => setFormData({ ...formData, relationship: e.target.value })} required />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update Child</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Children; 

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { API_URL } from "../config";
import { getCurrentDate } from '../Components/DateFunction';

const PastMembersList = () => {
    const [members, setMembers] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);

    // Pagination and sorting state
    const [currentPage, setCurrentPage] = useState(1);
    const [membersPerPage, setMembersPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedZone, setSelectedZone] = useState('');
    const [selectedGender, setSelectedGender] = useState('');
    // const [selectedStatus, setSelectedStatus] = useState('');

    // Sorting state
    const [sortField, setSortField] = useState('Surname');
    const [sortOrder, setSortOrder] = useState('asc');

    const navigate = useNavigate();

    useEffect(() => {
        fetchMembers();
    }, []);

    useEffect(() => {
        const results = members.filter(member =>
            (member.Name && member.Name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (member.Surname && member.Surname.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (member.Email && member.Email.toLowerCase().includes(searchTerm.toLowerCase()))
        ).filter(member =>
            (selectedZone ? member.Zone === selectedZone : true) &&
            (selectedGender ? member.Gender === selectedGender : true) //&&
            // (selectedStatus ? member.MembershipStatus === selectedStatus : true)
        );

        // Sort results
        results.sort((a, b) => {
            const aValue = a[sortField] ? a[sortField].toLowerCase() : "";
            const bValue = b[sortField] ? b[sortField].toLowerCase() : "";
            return sortOrder === 'asc' ? (aValue < bValue ? -1 : 1) : (aValue > bValue ? -1 : 1);
        });

        setFilteredMembers(results);
        setCurrentPage(1);
    }, [searchTerm, members, selectedZone, selectedGender, {/*selectedStatus,*/ }, sortField, sortOrder]);

    const fetchMembers = async () => {
        const response = await fetch(`${API_URL}/members/all/Inactive`);
        const data = await response.json();
        setMembers(data);
        setFilteredMembers(data);
    };

    const handleEditMember = (member) => {
        navigate(`/edit-pastmember/${member.MemberID}`);
    };

    // const handleViewMember = (member) => {
    //     navigate(`/view-member/${member.MemberID}`);
    // };

    const indexOfLastMember = currentPage * membersPerPage;
    const indexOfFirstMember = indexOfLastMember - membersPerPage;
    const currentMembers = filteredMembers.slice(indexOfFirstMember, indexOfLastMember);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const totalPages = Math.ceil(filteredMembers.length / membersPerPage);

    const handleRowsPerPageChange = (e) => {
        setMembersPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    const generatePDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(20);
        doc.text('New Life Covenant Church', 14, 22);
        doc.setFontSize(20);
        doc.text(`Past Members Report ${getCurrentDate()}`, 14, 35);
        doc.setFontSize(12);
        doc.text(`Total Past Members: ${filteredMembers.length}`, 14, 52);

        autoTable(doc, {
            startY: 62,
            head: [['Name', 'Surname', 'Email', 'Phone', 'Address', 'Zone']],
            body: filteredMembers.map(member => [
                member.Name,
                member.Surname,
                member.Email,
                member.Phone,
                member.Address,
                member.Zone
            ]),
        });
        doc.save('past_members_report.pdf');
    };

    // CSV File
    const generateCSV = () => {
        // Prepare the CSV data
        const csvData = [
            ['Name', 'Surname', 'Email', 'Phone', 'Address', 'Zone'], // Header
            ...filteredMembers.map(member => [
                member.Name,
                member.Surname,
                member.Email,
                member.Phone,
                member.Address,
                member.Zone
            ])
        ];

        // Convert the array to CSV format
        const csvContent = csvData.map(e => e.join(",")).join("\n");

        // Create a blob and generate a link to download the CSV
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `past_members_report_${getCurrentDate()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <div>
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Topnav />
                    <div className="container">
                        <div className="page-inner">
                            <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
                                <h2 className="text-capitalize font-weight-bold">
                                    Past Members ({filteredMembers.length})
                                </h2>
                                <div>
                                    <button className="btn btn-sm" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={generateCSV}>
                                        Generate CSV
                                    </button>&nbsp;
                                    <button className="btn btn-sm" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={generatePDF}>
                                        Generate PDF
                                    </button>
                                </div>
                            </div>

                            <div className="d-flex align-items-center mb-3">
                                <input
                                    type="text"
                                    className="form-control mr-2"
                                    placeholder="Search by Name, Surname, or Email"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{ flex: '2', minWidth: '300px' }}
                                />
                                <div className="mr-3 position-relative">
                                    <div onClick={() => document.getElementById('zoneSelect').focus()} style={{ cursor: 'pointer' }}>
                                        <select id="zoneSelect" className="form-control" value={selectedZone} onChange={(e) => setSelectedZone(e.target.value)} style={{ paddingRight: '30px' }}>
                                            <option value="">All Zones</option>
                                            <option>Eastern</option>
                                            <option>Western</option>
                                            <option>Northen</option>
                                            <option>Southern</option>
                                            <option>Central</option>
                                            <option>Chitungwiza</option>
                                        </select>
                                        <i className="fas fa-chevron-down position-absolute" style={{ right: '10px', top: '50%', transform: 'translateY(-50%)' }}></i>
                                    </div>
                                </div>
                                <div className="mr-3 position-relative">
                                    <div onClick={() => document.getElementById('genderSelect').focus()} style={{ cursor: 'pointer' }}>
                                        <select id="genderSelect" className="form-control" value={selectedGender} onChange={(e) => setSelectedGender(e.target.value)} style={{ paddingRight: '30px' }}>
                                            <option value="">All Genders</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                        <i className="fas fa-chevron-down position-absolute" style={{ right: '10px', top: '50%', transform: 'translateY(-50%)' }}></i>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped members-table" style={{ fontSize: '0.8rem' }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th onClick={() => handleSort('Name')} style={{ cursor: 'pointer' }}>
                                                Name
                                            </th>
                                            <th onClick={() => handleSort('Surname')} style={{ cursor: 'pointer' }}>
                                                Surname
                                            </th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th onClick={() => handleSort('Zone')} style={{ cursor: 'pointer' }}>
                                                Zone {getSortIcon('Zone')}
                                            </th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentMembers.map((member, index) => (
                                            <tr key={member.MemberID}>
                                                <td>{index + 1 + (currentPage - 1) * membersPerPage}</td>
                                                <td>{member.Name}</td>
                                                <td>{member.Surname}</td>
                                                <td>{member.Email}</td>
                                                <td>{member.Phone}</td>
                                                <td>{member.Zone}</td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <button type="button" className="btn btn-link" onClick={() => handleEditMember(member)}>
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </button>
                                                        {/* <button type="button" className="btn btn-link" onClick={() => handleViewMember(member)}>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </button> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {/* Pagination Controls */}
                            <nav>
                                <ul className="pagination justify-content-center">
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
                                            <button className="page-link" onClick={() => paginate(index + 1)}>
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                            {/* Rows per Page Selector at the bottom */}
                            <div className="mt-3">
                                <label htmlFor="rowsPerPage" className="mr-2">Rows per page:</label>
                                <select id="rowsPerPage" value={membersPerPage} onChange={handleRowsPerPageChange} className="form-control d-inline-block" style={{ width: 'auto' }}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={500}>500</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <Footer />
                    </footer>
                </div>
            </div>
        </div>
    );
};

export default PastMembersList;
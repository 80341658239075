import React, { useState, useEffect } from 'react';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSort, faSortUp, faSortDown, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { API_URL } from "../config";
import Swal from 'sweetalert2';

const VolunteerOpportunities = () => {
    const [events, setEvents] = useState([]);
    const [opportunities, setOpportunities] = useState([]);
    const [filteredOpportunities, setFilteredOpportunities] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentOpportunityId, setCurrentOpportunityId] = useState(null);
    const [formData, setFormData] = useState({
        event_id: '',
        task: '',
        requirements: '',
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [totalOpportunities, setTotalOpportunities] = useState(0);
    const [sortField, setSortField] = useState('Date');
    const [sortOrder, setSortOrder] = useState('asc');

    const fetchOpportunities = async () => {
        try {
            const response = await fetch(`${API_URL}/volunteer-tasks/join/events`);
            if (!response.ok) throw new Error('Failed to fetch tasks');
            const data = await response.json();
            // Format the date for each opportunity
            // const formattedData = data.map(opportunity => ({
            //     ...opportunity,
            //     Date: opportunity.Date.split('T')[0] // Convert to yyyy-MM-dd
            // }));
            setOpportunities(data);
            setFilteredOpportunities(data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchEvents = async () => {
        try {
            const response = await axios.get(`${API_URL}/events-tasks`);
            setEvents(response.data);
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    };

    useEffect(() => {
        fetchOpportunities();
        fetchEvents();
    }, []);

    useEffect(() => {
        const filtered = opportunities.filter(opportunity =>
        (opportunity.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
            opportunity.task.toLowerCase().includes(searchTerm.toLowerCase())) //&&
            //(!selectedDate || new Date(opportunity.date).toLocaleDateString() == new Date(selectedDate).toLocaleDateString())
        );
        setFilteredOpportunities(filtered);
        setTotalOpportunities(filtered.length);
    }, [searchTerm, selectedDate, opportunities]);
    

    const handleAddOpportunity = () => {
        setFormData({ event_id: '', task: '', requirements: '' });
        setShowAddModal(true);
    };

    const handleEditOpportunity = (opportunity) => {
        setFormData({
            event_id: opportunity.event_id,
            task: opportunity.task,
            requirements: opportunity.requirements,
        });
        setCurrentOpportunityId(opportunity.id);
        setShowEditModal(true);
    };

    const handleDeleteOpportunity = async (id) => {
        // if (window.confirm("Are you sure you want to delete this task?")) {
        //     try {
        //         const response = await fetch(`${API_URL}/volunteer-tasks/${id}`, {
        //             method: 'DELETE',
        //         });
        //         if (!response.ok) throw new Error('Failed to delete tas');
        //         fetchOpportunities();
        //     } catch (error) {
        //         console.error(error);
        //     }
        // }
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });
    
        if (result.isConfirmed) {
            try {
                await fetch(`${API_URL}/volunteer-tasks/${id}`, {
                    method: 'DELETE',
                });
                fetchOpportunities();
                Swal.fire({
                    text: "Task successfully deleted",
                    icon: "success"
                });
            } catch (error) {
                console.error(error);
                Swal.fire({
                    text: "Failed to delete task",
                    icon: "error"
                });
            }
        } else {
            Swal.fire({
                text: "Deletion canceled",
                icon: "info"
            });
        }
    };

    const handleSubmitAdd = async (e) => {
        e.preventDefault();
        // try {
        //     const response = await fetch(`${API_URL}/volunteer-tasks`, {
        //         method: 'POST',
        //         headers: { 'Content-Type': 'application/json' },
        //         body: JSON.stringify(formData),
        //     });
        //     if (!response.ok) throw new Error('Failed to add opportunity');
        //     fetchOpportunities();
        //     setShowAddModal(false);
        // } catch (error) {
        //     console.error(error);
        // }


        try {
            const response =  await fetch(`${API_URL}/volunteer-tasks`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to update task: ${errorText}`);
            }
    
            await fetchOpportunities();
            setShowAddModal(false);
            Swal.fire({
                text: "Successfully Updated Task",
                icon: "success"
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                text: "Failed to add Task",
                icon: "error"
            });
        }

    };

    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        // try {
        //     const response = await fetch(`${API_URL}/volunteer-tasks/${currentOpportunityId}`, {
        //         method: 'PUT',
        //         headers: { 'Content-Type': 'application/json' },
        //         body: JSON.stringify(formData),
        //     });
        //     if (!response.ok) throw new Error('Failed to update opportunity');
        //     fetchOpportunities();
        //     setShowEditModal(false);
        // } catch (error) {
        //     console.error(error);
        // }


        try {
            const response = await fetch(`${API_URL}/volunteer-tasks/${currentOpportunityId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to update task: ${errorText}`);
            }
    
            await fetchOpportunities();
            setShowEditModal(false);
            Swal.fire({
                text: "Successfully Updated Task",
                icon: "success"
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                text: "Failed to add Task",
                icon: "error"
            });
        }
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
        const sortedOpportunities = [...filteredOpportunities].sort((a, b) => {
            return order === 'asc' ? (a[field] > b[field] ? 1 : -1) : (a[field] < b[field] ? 1 : -1);
        });
        setFilteredOpportunities(sortedOpportunities);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    return (
        <div>
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Topnav />
                    <div className="container">
                        <div className="page-inner">
                            <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
                                <h2 className="text-capitalize font-weight-bold">
                                    Volunteer Tasks ({totalOpportunities})
                                </h2>
                                <button className="btn btn-sm" onClick={handleAddOpportunity} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                    <FontAwesomeIcon icon={faPlus} /> Add Task
                                </button>
                            </div>

                            <div className="d-flex mb-4">
                                <div className="form-group" style={{ flex: '0 0 100%' }}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by event or task"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                                {/* <div className="form-group" style={{ flex: '0 0 25%' }}>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={selectedDate}
                                        onChange={(e) => setSelectedDate(e.target.value)}
                                    />
                                </div> */}
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th >
                                                Event 
                                            </th>
                                            <th >
                                                Task
                                            </th>
                                            <th>Requirements</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredOpportunities.map((opportunity, index) => (
                                            <tr key={index}>
                                                <td>{opportunity.date.slice(0, 10)}</td>
                                                <td>{opportunity.type}</td>
                                                <td>{opportunity.task}</td>
                                                <td>{opportunity.requirements}</td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <button onClick={() => handleEditOpportunity(opportunity)} className="btn btn-link ">
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </button>
                                                        <button onClick={() => handleDeleteOpportunity(opportunity.id)} className="btn btn-link">
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <Footer />
                    </footer>
                </div>
            </div>

            {/* Add Opportunity Modal */}
            {showAddModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowAddModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Volunteer Task</h5>
                                <button type="button" className="close" onClick={() => setShowAddModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitAdd}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label htmlFor="eventId" className='modal-label'>Event</label>
                                        <select
                                            id="eventId"
                                            value={formData.event_id}
                                            onChange={(e) => setFormData({ ...formData, event_id: e.target.value })}
                                            required
                                            className="form-control"
                                        >
                                            <option value="">Select an event</option>
                                            {events.map(event => (
                                                <option key={event.id} value={event.id}>
                                                    {event.type}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Task</label>
                                        <input type="text" className="form-control" value={formData.task} onChange={(e) => setFormData({ ...formData, task: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className='modal-label'>Requirements</label>
                                        <input type="text" className="form-control" value={formData.requirements} onChange={(e) => setFormData({ ...formData, requirements: e.target.value })} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowAddModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Add Task</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Opportunity Modal */}
            {showEditModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowEditModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Volunteer Task</h5>
                                <button type="button" className="close" onClick={() => setShowEditModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitEdit}>
                                <div className="modal-body">
                                <div className="form-group">
                                        <label htmlFor="eventId">Event</label>
                                        <select
                                            id="eventId"
                                            value={formData.event_id}
                                            onChange={(e) => setFormData({ ...formData, event_id: e.target.value })}
                                            required
                                            className="form-control"
                                        >
                                            <option value="">Select an event</option>
                                            {events.map(event => (
                                                <option key={event.id} value={event.id}>
                                                    {event.type}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Task</label>
                                        <input type="text" className="form-control" value={formData.task} onChange={(e) => setFormData({ ...formData, task: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label>Requirements</label>
                                        <input type="text" className="form-control" value={formData.requirements} onChange={(e) => setFormData({ ...formData, requirements: e.target.value })} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update Task</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VolunteerOpportunities;

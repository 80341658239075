import React, { useState } from "react";
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
// import { useParams } from "react-router-dom";
import { API_URL } from "../config";
import Swal from "sweetalert2";

const Password = () => {

    // const { id } = useParams();

    const storedUser = localStorage.getItem('user');
    const user = JSON.parse(storedUser);

    const [formData, setFormData] = useState({
        email: user.email,
        oldPassword: '',
        newPassword: '',
        ConfirmPassword: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.newPassword === formData.ConfirmPassword) {
            try {
                const response = await fetch(`${API_URL}/users/resetpassword`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formData),
                });
        
                if (response.ok) {
                    // alert('Password Changed Successfully!');
                    Swal.fire({
                        text: "Password Changed Successfully!",
                        icon: "success"
                    });
                } else {
                    // alert('Password Reset Failed!');
                    Swal.fire({
                        text: "Password Reset Failed!",
                        icon: "error"
                    });
                }
            } catch (error) {
                console.error("Error resetting password:", error);
                Swal.fire({
                    text: "An error occurred while resetting the password.",
                    icon: "error"
                });
            }
        }
        
        else {
            // alert('Passwords are not matching')
            Swal.fire({
                text: "Passwords are not matching!",
                icon: "error"
            })
        }
    };

    return (
        <html lang="en">
            <body>
                <div className="wrapper">
                    <Sidebar></Sidebar>
                    <div className="main-panel">
                        <Topnav></Topnav>

                        <div className="container">
                            <div className="page-inner">
                                <form onSubmit={handleSubmit} className="page-inner">
                                    <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                                        <h3 className="fw-bold mb-3">Change Password</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label htmlFor="Name">Old Password</label>
                                            <input
                                                type="password"
                                                name="oldPassword"
                                                id="oldPassword"
                                                className="form-control"
                                                style={{ border: '0.5px solid darkblue' }}
                                                value={formData.oldPassword}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <label htmlFor="Surname">New Password</label>
                                            <input
                                                type="password"
                                                name="newPassword"
                                                id="Surname"
                                                className="form-control"
                                                style={{ border: '0.5px solid darkblue' }}
                                                value={formData.newPassword}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div> <br></br>
                                    <div className="row">
                                    </div><br></br>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label htmlFor="Surname">Confirm Password</label>
                                            <input
                                                type="password"
                                                name="ConfirmPassword"
                                                id="Surname"
                                                className="form-control"
                                                style={{ border: '0.5px solid darkblue' }}
                                                value={formData.ConfirmPassword}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <label>&nbsp;</label>
                                            <button type="submit" className="btn form-control" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                                Update
                                            </button>
                                        </div>
                                    </div><br></br><br></br>

                                    <div className="row">
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-lg-8"></div>
                                        <div className="col-lg-2">
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <footer className="footer">
                            <Footer></Footer>
                        </footer>
                    </div>
                </div>
            </body>
        </html>
    );
};

export default Password;

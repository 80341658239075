import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSort, faSortUp, faSortDown, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { API_URL } from "../config";
import { getCurrentDate } from '../Components/DateFunction';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Swal from 'sweetalert2';

const Events = () => {
    const [events, setEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentEventId, setCurrentEventId] = useState(null);
    const [formData, setFormData] = useState({
        type: '',
        theme: '',
        date: '',
        time: '',
        enddate: '',
        endtime: '',
        location: '',
        description: ''
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [startDateFilter, setStartDateFilter] = useState('');
    const [totalEvents, setTotalEvents] = useState(0);
    const [sortField, setSortField] = useState('Date');
    const [sortOrder, setSortOrder] = useState('asc');

    const fetchEvents = async () => {
        const response = await fetch(`${API_URL}/events-tasks`);
        const data = await response.json();
        setEvents(data);
        setFilteredEvents(data);
        setTotalEvents(data.length);
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    useEffect(() => {
        const filtered = events.filter(event =>
            (event.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
                event.theme.toLowerCase().includes(searchTerm.toLowerCase()) ||
                event.location.toLowerCase().includes(searchTerm.toLowerCase())) &&
            (!startDateFilter || new Date(event.date) >= new Date(startDateFilter))
        );
        setFilteredEvents(filtered);
        setTotalEvents(filtered.length);
    }, [searchTerm, startDateFilter, events]);

    const handleAddEvent = () => {
        setFormData({
            type: '',
            theme: '',
            date: '',
            time: '',
            enddate: '',
            endtime: '',
            location: '',
            description: ''
        });
        setShowAddModal(true);
    };

    const handleEditEvent = (event) => {
        setFormData(event);
        setCurrentEventId(event.id);
        setShowEditModal(true);
    };

    const handleDeleteEvent = async (id) => {
        // if (window.confirm("Are you sure you want to delete this event?")) {
        //     await fetch(`${API_URL}/events-tasks/${id}`, {
        //         method: 'DELETE',
        //     });
        //     fetchEvents();
        // }

        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });
    
        if (result.isConfirmed) {
            try {
                await fetch(`${API_URL}/events-tasks/${id}`, {
                    method: 'DELETE',
                });
                fetchEvents();
                Swal.fire({
                    text: "Event successfully deleted",
                    icon: "success"
                });
            } catch (error) {
                console.error(error);
                Swal.fire({
                    text: "Failed to delete member",
                    icon: "error"
                });
            }
        } else {
            Swal.fire({
                text: "Deletion canceled",
                icon: "info"
            });
        }
    };

    const handleSubmitAdd = async (e) => {
        // e.preventDefault();
        // await fetch(`${API_URL}/events-tasks`, {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify(formData),
        // });
        // fetchEvents();
        // setShowAddModal(false);



        e.preventDefault();
        try {
            const response =  await fetch(`${API_URL}/events-tasks`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to add event: ${errorText}`);
            }
    
            setShowAddModal(false);
            await fetchEvents();
            Swal.fire({
                text: "Successfully Added Event",
                icon: "success"
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                text: "Failed to add Event",
                icon: "error"
            });
        }
    };

    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        // await fetch(`${API_URL}/events-tasks/${currentEventId}`, {
        //     method: 'PUT',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify(formData),
        // });
        // fetchEvents();
        // setShowEditModal(false);

        try {
            const response =  await fetch(`${API_URL}/events-tasks/${currentEventId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to update event: ${errorText}`);
            }
    
            setShowEditModal(false);
            await fetchEvents();
            Swal.fire({
                text: "Successfully Updated Event",
                icon: "success"
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                text: "Failed to add Event",
                icon: "error"
            });
        }
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
        const sortedEvents = [...filteredEvents].sort((a, b) => {
            return order === 'asc' ? (a[field] > b[field] ? 1 : -1) : (a[field] < b[field] ? 1 : -1);
        });
        setFilteredEvents(sortedEvents);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    const generatePDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(20);
        doc.text('New Life Covenant Church', 14, 22);
        doc.setFontSize(15);
        doc.text(`Events Report ${getCurrentDate()}`, 14, 35);
        doc.setFontSize(12);
        doc.text(`Total Events: $${filteredEvents.length}`, 14, 52);

        autoTable(doc, {
            startY: 62,
            head: [['Date', 'Name', 'Theme', 'Description', 'Location']],
            body: filteredEvents.map(event => [
                new Date(event.date).toLocaleDateString(),
                event.type,
                event.theme,
                event.description,
                event.location
            ]),
        });

        doc.save('events_report.pdf');
    };

    const generateCSV = () => {
        const csvRows = [];
        
        // Add header row
        csvRows.push(['Date', 'Name', 'Theme', 'Description', 'Location'].join(','));
        
        // Add data rows
        filteredEvents.forEach(event => {
            const row = [
                new Date(event.date).toLocaleDateString(),
                event.type,
                event.theme,
                event.description,
                event.location
            ];
            csvRows.push(row.join(','));
        });
        
        // Create a CSV string
        const csvString = csvRows.join('\n');
        
        // Create a Blob from the CSV string
        const blob = new Blob([csvString], { type: 'text/csv' });
        
        // Create a link element to download the CSV
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'events_report.csv'; 
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url); 
    };
    

    return (
        <div>
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Topnav />
                    <div className="container">
                        <div className="page-inner">
                            <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
                                <h2 className="text-capitalize font-weight-bold">
                                    Events ({totalEvents})
                                </h2>
                                <div>
                                    <button className="btn btn-sm" onClick={generateCSV} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        Generate CSV
                                    </button> &nbsp;
                                    <button className="btn btn-sm" onClick={generatePDF} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        Generate PDF
                                    </button> &nbsp;
                                    <button className="btn btn-sm" onClick={handleAddEvent} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        <FontAwesomeIcon icon={faPlus} /> Add Event
                                    </button>
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <div className="d-flex">
                                    <input
                                        type="text"
                                        className="form-control me-2"
                                        placeholder="Search by Event Name, Location"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        style={{ flex: '0 0 100%' }}
                                    />
                                    {/* <input
                                        type="date"
                                        className="form-control"
                                        placeholder="Start Date"
                                        value={startDateFilter}
                                        onChange={(e) => setStartDateFilter(e.target.value)}
                                        style={{ flex: '0 0 25%' }}
                                    /> */}
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th onClick={() => handleSort('Date')} style={{ cursor: 'pointer' }}>
                                                Date {getSortIcon('Date')}
                                            </th>
                                            <th onClick={() => handleSort('Name')} style={{ cursor: 'pointer' }}>
                                                Event Name {getSortIcon('Name')}
                                            </th>
                                            <th onClick={() => handleSort('Location')} style={{ cursor: 'pointer' }}>
                                                Location {getSortIcon('Location')}
                                            </th>
                                            <th>Description</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredEvents.map((event, index) => (
                                            <tr key={index}>
                                                <td>{new Date(event.date).toLocaleDateString()}</td>
                                                <td>{event.type}</td>
                                                <td>{event.location}</td>
                                                <td>{event.description}</td>
                                                <td>
                                                    <button onClick={() => handleEditEvent(event)} className="btn btn-link mr-2">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                    <button onClick={() => handleDeleteEvent(event.id)} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <Footer />
                    </footer>
                </div>
            </div>

            {/* Add Event Modal */}
            {showAddModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowAddModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Add Event</h5>
                                <button type="button" className="close" onClick={() => setShowAddModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitAdd}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="modal-label">Name</label>
                                        <input type="text" className="form-control" value={formData.type} onChange={(e) => setFormData({ ...formData, type: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Theme</label>
                                        <input type="text" className="form-control" value={formData.theme} onChange={(e) => setFormData({ ...formData, theme: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Start Date</label>
                                        <input type="date" className="form-control" value={formData.date} onChange={(e) => setFormData({ ...formData, date: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Start Time</label>
                                        <input type="time" className="form-control" value={formData.time} onChange={(e) => setFormData({ ...formData, time: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">End Date</label>
                                        <input type="date" className="form-control" value={formData.enddate} onChange={(e) => setFormData({ ...formData, enddate: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">End Time</label>
                                        <input type="time" className="form-control" value={formData.endtime} onChange={(e) => setFormData({ ...formData, endtime: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Location</label>
                                        <input type="text" className="form-control" value={formData.location} onChange={(e) => setFormData({ ...formData, location: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Description</label>
                                        <textarea type="text" className="form-control" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowAddModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Add Event</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Event Modal */}
            {showEditModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowEditModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Event</h5>
                                <button type="button" className="close" onClick={() => setShowEditModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitEdit}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="modal-label">Name</label>
                                        <input type="text" className="form-control" value={formData.type} onChange={(e) => setFormData({ ...formData, type: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Theme</label>
                                        <input type="text" className="form-control" value={formData.theme} onChange={(e) => setFormData({ ...formData, theme: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Start Date</label>
                                        <input type="date" className="form-control" value={formData.date.slice(0, 10)} onChange={(e) => setFormData({ ...formData, date: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Start Time</label>
                                        <input type="time" className="form-control" value={formData.time} onChange={(e) => setFormData({ ...formData, time: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">End Date</label>
                                        <input type="date" className="form-control" value={formData.enddate.slice(0, 10)} onChange={(e) => setFormData({ ...formData, enddate: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">End Time</label>
                                        <input type="time" className="form-control" value={formData.endtime} onChange={(e) => setFormData({ ...formData, endtime: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Location</label>
                                        <input type="text" className="form-control" value={formData.location} onChange={(e) => setFormData({ ...formData, location: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Description</label>
                                        <textarea type="text" className="form-control" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update Event</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Events; 

import React, { useEffect, useState } from 'react';
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { useParams, useNavigate } from 'react-router-dom'; // Removed useNavigate
import { API_URL } from '../config';

const ViewMember = () => {
    const { id } = useParams();
    const [memberData, setMemberData] = useState(null);
    const [connResult, setConnResult] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMemberData = async () => {
            try {
                const response = await fetch(`${API_URL}/members/${id}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                // Assuming the API returns an array, get the first element
                setMemberData(data[0]);
                console.log(data[0]);
            } catch (error) {
                console.error('Error fetching member data:', error);
                setConnResult('Error fetching member data.'); // Set a meaningful message
            }
        };

        fetchMemberData();
    }, [id]);

    if (!memberData) {
        return <div>Loading...</div>; // Loading state
    }

    const navigateBack = () => {
        navigate('/members');
    }

    return (
        <div className="wrapper">
            <Sidebar />
            <div className="main-panel">
                <Topnav />
                <div className="container">
                    <div className="page-inner">
                        <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                            <h3 className="fw-bold mb-3">Member Details</h3>
                        </div>
                        {memberData.ProfilePicture && (
                            <div className="text-center mb-4">
                                <label>Profile Picture</label>
                                <div>
                                    <img
                                        src={memberData.ProfilePicture}
                                        alt="Profile"
                                        style={{ width: '200px', height: '200px', borderRadius: '50%' }}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-lg-4">
                                <label>Member ID</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={memberData.MemberID}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-4">
                                <label>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={memberData.Name}
                                // disabled
                                />
                            </div>
                            <div className="col-lg-4">
                                <label>Surname</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={memberData.Surname}
                                // disabled
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <label>Gender</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={memberData.Gender}
                                // disabled
                                />
                            </div>
                            <div className="col-lg-4">
                                <label>Phone</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={memberData.Phone}
                                // disabled
                                />
                            </div>
                            <div className="col-lg-4">
                                <label>Email</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={memberData.Email}
                                // disabled
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <label>Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={memberData.Address}
                                // disabled
                                />
                            </div>
                            <div className="col-lg-4">
                                <label>City</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={memberData.City}
                                // disabled
                                />
                            </div>
                            <div className="col-lg-4">
                                <label>Country</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={memberData.Country}
                                // disabled
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <label>Zone</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={memberData.Zone}
                                // disabled
                                />
                            </div>
                            <div className="col-lg-4">
                                <label>Membership Status</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={memberData.MembershipStatus}
                                // disabled
                                />
                            </div>
                        </div>
                        <div><br></br>
                            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                                <h3 className="fw-bold mb-3">Next of Kin</h3>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={memberData.nxt_of_kin}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <label>Relationship</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={memberData.nok_relationship}
                                    // disabled
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <label>Phone</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={memberData.nok_phone}
                                    // disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div><br></br>
                            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                                <h3 className="fw-bold mb-3">Spouse Contact</h3>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={memberData.sponame}
                                    />
                                </div>
                                {/* <div className="col-lg-4">
                                    <label>Relationship</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={memberData.emerg_con_relationship}
                                    // disabled
                                    />
                                </div> */}
                                <div className="col-lg-4">
                                    <label>Phone</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={memberData.spophone}
                                    // disabled
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Display connection result if there's an error */}
                        {connResult && (
                            <div className="alert alert-danger mt-3">
                                {connResult}
                            </div>
                        )}
                    </div>
                    <div className="col-lg-4" style={{ float: 'right' }}>
                        <button type="button" className="btn form-control" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={navigateBack}>
                            Back
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewMember;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { API_URL } from "../config";
// import jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable';
import { getCurrentDate } from '../Components/DateFunction';
import Swal from 'sweetalert2';

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentProjectId, setCurrentProjectId] = useState(null);
    const [formData, setFormData] = useState({ ProjectID: '', ProjectName: '', StartDate: getCurrentDate(), Description: '', Status: 'Active' });
    const [searchTerm, setSearchTerm] = useState('');
    // const [sortField, setSortField] = useState('Name');
    // const [sortOrder, setSortOrder] = useState('asc');

    const fetchProjects = async () => {
        try {
            const response = await fetch(`${API_URL}/projects`);
            if (!response.ok) throw new Error('Failed to fetch Projects');
            const data = await response.json();
            setProjects(data);
            setFilteredProjects(data);
        } catch (error) {
            console.error(error);
            alert('Error fetching Projects: ' + error.message);
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    useEffect(() => {
        const filtered = projects.filter(project =>
            (project.ProjectName ? project.ProjectName.toLowerCase() : '').includes(searchTerm.toLowerCase())
        );
        setFilteredProjects(filtered);
    }, [searchTerm, projects]);

    const handleAddProject = () => {
        setFormData({ name: '', weblink: '', date: '' });
        setShowAddModal(true);
    };

    const handleEditProject = (project) => {
        setFormData(project);
        setCurrentProjectId(project.id);
        setShowEditModal(true);
    };

    // const handleDeleteProject = async (id) => {
    //     if (window.confirm("Are you sure you want to delete this project?")) {
    //         await fetch(`${API_URL}/projects/${id}`, { method: 'DELETE' });
    //         fetchProjects();
    //     }
    // };

    // const handleSubmitAdd = async (e) => {
    //     e.preventDefault();

    //     // Add the project   
    //     await fetch(`${API_URL}/projects`, {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify(formData),
    //     });

    //     fetchProjects();
    //     setShowAddModal(false);

    // };

    // const handleSubmitEdit = async (e) => {
    //     e.preventDefault();
    //     console.log("Updating group with ID:", currentProjectId);
    //     console.log("Form Data:", formData); // Log form data

    //     try {
    //         const response = await fetch(`${API_URL}/projects/${formData.ProjectID}`, {
    //             method: 'PUT',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify(formData),
    //         });

    //         if (!response.ok) {
    //             const errorText = await response.text(); // Get error text for better debugging
    //             throw new Error(`Failed to update the Project: ${errorText}`);
    //         }

    //         fetchProjects();
    //         setShowEditModal(false);
    //     } catch (error) {
    //         console.error(error);
    //         alert('Error updating Project: ' + error.message);
    //     }
    // };

    const handleDeleteProject = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });
    
        if (result.isConfirmed) {
            try {
                await fetch(`${API_URL}/projects/${id}`, { method: 'DELETE' });
                fetchProjects(); // Refresh the project list
                
                Swal.fire({
                    text: "Project successfully deleted",
                    icon: "success"
                });
            } catch (error) {
                console.error("Error deleting project:", error);
                Swal.fire({
                    text: "Failed to delete project",
                    icon: "error"
                });
            }
        } else {
            Swal.fire({
                text: "Deletion canceled",
                icon: "info"
            });
        }
    };
    
    const handleSubmitAdd = async (e) => {
        e.preventDefault();
    
        try {
            await fetch(`${API_URL}/projects`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
    
            fetchProjects(); // Refresh the project list
            setShowAddModal(false);
            
            Swal.fire({
                text: "Project successfully added",
                icon: "success"
            });
        } catch (error) {
            console.error("Error adding project:", error);
            Swal.fire({
                text: "Failed to add project",
                icon: "error"
            });
        }
    };
    
    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        console.log("Updating project with ID:", currentProjectId);
        console.log("Form Data:", formData); // Log form data
    
        try {
            const response = await fetch(`${API_URL}/projects/${formData.ProjectID}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
    
            if (!response.ok) {
                const errorText = await response.text(); // Get error text for better debugging
                throw new Error(`Failed to update the Project: ${errorText}`);
            }
    
            fetchProjects(); // Refresh the project list
            setShowEditModal(false);
            
            Swal.fire({
                text: "Project successfully updated",
                icon: "success"
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                text: "Error updating project: " + error.message,
                icon: "error"
            });
        }
    };
    

    return (
        <div>
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Topnav />
                    <div className="container">
                        <div className="page-inner">
                            <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
                                <h2 className="text-capitalize font-weight-bold">
                                Categories ({filteredProjects.length})
                                </h2>
                                <div>
                                    <button className="btn btn-sm" onClick={handleAddProject} style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        <FontAwesomeIcon icon={faPlus} /> Add Category
                                    </button>
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by name"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredProjects.map((project) => (
                                            <tr key={project.id}>
                                                <td>{project.ProjectName}</td>
                                                <td>{project.Description}</td>
                                                <td>
                                                    <button onClick={() => handleEditProject(project)} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                    <button onClick={() => handleDeleteProject(project.ProjectID)} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <Footer />
                    </footer>
                </div>
            </div>

            {/* Add Project Modal */}
            {showAddModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowAddModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Category</h5>
                                <button type="button" className="close" onClick={() => setShowAddModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitAdd}>
                                <div className="modal-body">
                                <div className="form-group">
                                        <label className="modal-label">Name</label>
                                        <input type="text" className="form-control" value={formData.ProjectName} onChange={(e) => setFormData({ ...formData, ProjectName: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Description</label>
                                        <input type="text" className="form-control" value={formData.Description} onChange={(e) => setFormData({ ...formData, Description: e.target.value })} required />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowAddModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Add Category</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Project Modal */}
            {showEditModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowEditModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Category</h5>
                                <button type="button" className="close" onClick={() => setShowEditModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitEdit}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="modal-label">Name</label>
                                        <input type="text" className="form-control" value={formData.ProjectName} onChange={(e) => setFormData({ ...formData, ProjectName: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Description</label>
                                        <input type="text" className="form-control" value={formData.Description} onChange={(e) => setFormData({ ...formData, Description: e.target.value })} required />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update Category</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Projects;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { API_URL } from "../config";
import { getCurrentDate } from '../Components/DateFunction';
import { getCurrentTime } from '../Components/TimeFunction';
import Swal from 'sweetalert2';

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentNotificationId, setCurrentNotificationId] = useState(null);
    const [formData, setFormData] = useState({ header: '', content: '', date: '', time: '', ministryID: '' });
    const [searchTerm, setSearchTerm] = useState('');
    const [ministries, setMinistries] = useState([]);

    const allMembers = 0

    const fetchNotifications = async () => {
        try {
            const response = await fetch(`${API_URL}/notifications`);
            if (!response.ok) throw new Error('Failed to fetch Notifications');
            const data = await response.json();
            setNotifications(data);
            setFilteredNotifications(data);
        } catch (error) {
            console.error(error);
            alert('Error fetching Notifications: ' + error.message);
        }
    };

    const fetchMinistries = async () => {
        try {
            const response = await fetch(`${API_URL}/ministries`);
            if (!response.ok) throw new Error('Failed to fetch ministries');
            const data = await response.json();
            setMinistries(data);
        } catch (error) {
            console.error('Error fetching ministries:', error);
        }
    };

    useEffect(() => {
        fetchNotifications();
        fetchMinistries();
    }, []);

    useEffect(() => {
        const filtered = notifications.filter(notification =>
            (notification.header ? notification.header.toLowerCase() : '').includes(searchTerm.toLowerCase())
        );
        setFilteredNotifications(filtered);
    }, [searchTerm, notifications]);

    // Function to get member name by ID
    const getMinistryName = (MinistryID) => {
        const allMembers = "All Members"
        if (MinistryID === 0 || MinistryID === '0') {
            return allMembers
        }
        const ministry = ministries.find(m => m.MinistryID === MinistryID);
        return ministry ? `${ministry.Name}` : 'Unknown';
    };

    const handleAddNotification = () => {
        setFormData({ header: '', content: '', date: getCurrentDate(), time: getCurrentTime(), MemberID: '100' });
        setShowAddModal(true);
    };

    const handleEditNotification = (notification) => {
        setFormData(notification);
        setCurrentNotificationId(notification.id);
        setShowEditModal(true);
    };

    // const handleDeleteNotification = async (id) => {
    //     if (window.confirm("Are you sure you want to delete this notification?")) {
    //         await fetch(`${API_URL}/notifications/${id}`, { method: 'DELETE' });
    //         fetchNotifications();
    //     }
    // };

    // const handleSubmitAdd = async (e) => {
    //     e.preventDefault();

    //     // Add the notification   
    //     await fetch(`${API_URL}/notifications`, {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify(formData),
    //     });

    //     fetchNotifications();
    //     setShowAddModal(false);

    // };

    // const handleSubmitEdit = async (e) => {
    //     e.preventDefault();
    //     console.log("Updating group with ID:", currentNotificationId);
    //     console.log("Form Data:", formData); 

    //     try {
    //         const response = await fetch(`${API_URL}/notifications/${formData.id}`, {
    //             method: 'PUT',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify(formData),
    //         });

    //         if (!response.ok) {
    //             const errorText = await response.text(); 
    //             throw new Error(`Failed to update the Notification: ${errorText}`);
    //         }

    //         fetchNotifications();
    //         setShowEditModal(false);
    //     } catch (error) {
    //         console.error(error);
    //         alert('Error updating Notification: ' + error.message);
    //     }
    // };

    const handleDeleteNotification = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });

        if (result.isConfirmed) {
            try {
                await fetch(`${API_URL}/notifications/${id}`, { method: 'DELETE' });
                fetchNotifications(); // Refresh the notification list

                Swal.fire({
                    text: "Notification successfully deleted",
                    icon: "success"
                });
            } catch (error) {
                console.error("Error deleting notification:", error);
                Swal.fire({
                    text: "Failed to delete notification",
                    icon: "error"
                });
            }
        } else {
            Swal.fire({
                text: "Deletion canceled",
                icon: "info"
            });
        }
    };

    const handleSubmitAdd = async (e) => {
        e.preventDefault();

        try {
            // Add the notification   
            await fetch(`${API_URL}/notifications`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            fetchNotifications(); // Refresh the notification list
            setShowAddModal(false);

            Swal.fire({
                text: "Notification successfully added",
                icon: "success"
            });
        } catch (error) {
            console.error("Error adding notification:", error);
            Swal.fire({
                text: "Failed to add notification",
                icon: "error"
            });
        }
    };

    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        console.log("Updating notification with ID:", currentNotificationId);
        console.log("Form Data:", formData);

        try {
            const response = await fetch(`${API_URL}/notifications/${formData.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to update the Notification: ${errorText}`);
            }

            fetchNotifications(); // Refresh the notification list
            setShowEditModal(false);

            Swal.fire({
                text: "Notification successfully updated",
                icon: "success"
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                text: "Error updating notification: " + error.message,
                icon: "error"
            });
        }
    };


    return (
        <div>
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Topnav />
                    <div className="container">
                        <div className="page-inner">
                            <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
                                <h2 className="text-capitalize font-weight-bold">
                                    Notifications ({filteredNotifications.length})
                                </h2>
                                <div>
                                    <button className="btn btn-sm" onClick={handleAddNotification} style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                        <FontAwesomeIcon icon={faPlus} /> Add Notification
                                    </button>
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by header"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Header</th>
                                            <th>Content</th>
                                            <th>Recipients</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredNotifications.map((notification) => (
                                            <tr key={notification.id}>
                                                <td>{notification.header}</td>
                                                <td>{notification.content}</td>
                                                {/* <td>{notification.ministryID}</td> */}
                                                <td>{getMinistryName(notification.ministryID)}</td>
                                                <td>{notification.date.slice(0, 10)}</td>
                                                <td>{notification.time}</td>
                                                <td>
                                                    <button onClick={() => handleEditNotification(notification)} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                    <button onClick={() => handleDeleteNotification(notification.id)} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <Footer />
                    </footer>
                </div>
            </div>

            {/* Add Notification Modal */}
            {showAddModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowAddModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Add Notification</h5>
                                <button type="button" className="close" onClick={() => setShowAddModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitAdd}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="modal-label">Header</label>
                                        <input type="text" className="form-control" value={formData.header} onChange={(e) => setFormData({ ...formData, header: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Content</label>
                                        <input type="text" className="form-control" value={formData.content} onChange={(e) => setFormData({ ...formData, content: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="modal-label">Recipient</label>
                                        <select
                                            className="form-control"
                                            value={formData.ministryID}
                                            onChange={(e) => setFormData({ ...formData, ministryID: e.target.value })}
                                            required
                                        >
                                            <option value="">Select a recipient</option>
                                            <option value={allMembers}>All Members</option>
                                            {ministries.map((ministry) => (
                                                <>
                                                    <option key={ministry.MinistryID} value={ministry.MinistryID}>
                                                        {ministry.Name}
                                                    </option>
                                                </>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowAddModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Add Notification</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Notification Modal */}
            {showEditModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowEditModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Notification</h5>
                                <button type="button" className="close" onClick={() => setShowEditModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmitEdit}>
                                <div className="modal-body">
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <label className="modal-label">Header</label>
                                            <input type="text" className="form-control" value={formData.header} onChange={(e) => setFormData({ ...formData, header: e.target.value })} required />
                                        </div>
                                        <div className="form-group">
                                            <label className="modal-label">Content</label>
                                            <input type="text" className="form-control" value={formData.content} onChange={(e) => setFormData({ ...formData, content: e.target.value })} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update Notification</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Notifications;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Topnav = () => {

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('user') === null) {
            navigate('/')
        } else {
            const storedUser = localStorage.getItem('user');
            const user = JSON.parse(storedUser);

            setName(user.user_name);
            setSurname(user.surname);
            setEmail(user.email);
        }
    }, [])

    return (
        <>
            <div className="main-header">
                <div className="main-header-logo">
                    <div className="logo-header" data-background-color="dark">
                        <a href="index.html" className="logo">
                            <img
                                src="../assets/img/nlcc-logo.png"
                                alt="nlcc logo"
                                className="navbar-brand"
                                height="40"
                                width="120"
                            />
                        </a>
                        <div className="nav-toggle">
                            <button className="btn btn-toggle toggle-sidebar">
                                <i className="gg-menu-right"></i>
                            </button>
                            <button className="btn btn-toggle sidenav-toggler">
                                <i className="gg-menu-left"></i>
                            </button>
                        </div>
                        <button className="topbar-toggler more">
                            <i className="gg-more-vertical-alt"></i>
                        </button>
                    </div>
                </div>
                <nav className="navbar navbar-header navbar-header-transparent navbar-expand-lg border-bottom">
                    <div className="container-fluid">
                        <nav className="navbar navbar-header-left navbar-expand-lg navbar-form nav-search p-0 d-none d-lg-flex">
                             {/* <div className="input-group">
                                <div className="input-group-prepend">
                                    <button type="submit" className="btn btn-search pe-1">
                                        <i className="fa fa-search search-icon"></i>
                                    </button>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Search .."
                                    className="form-control"
                                    disabled
                                />
                            </div>*/}
                            <div >
                                <h2>New Life Covenant Church</h2>
                            </div>
                        </nav>

                        <ul className="navbar-nav topbar-nav ms-md-auto align-items-center">
                            <li className="nav-item topbar-icon dropdown hidden-caret d-flex d-lg-none">
                                <a
                                    className="nav-link dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    href="0"
                                    role="button"
                                    aria-expanded="false"
                                    aria-haspopup="true"
                                >
                                    <i className="fa fa-search"></i>
                                </a>
                                <ul className="dropdown-menu dropdown-search animated fadeIn">
                                    <form className="navbar-left navbar-form nav-search">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                placeholder="Search .."
                                                className="form-control"
                                            />
                                        </div>
                                    </form>
                                </ul>
                            </li>

                            <li className="nav-item topbar-user dropdown hidden-caret">
                                <a
                                    className="dropdown-toggle profile-pic"
                                    data-bs-toggle="dropdown"
                                    href="0"
                                    aria-expanded="false"
                                >
                                    <div className="avatar-sm">
                                        <img
                                            src="../assets/img/avator.PNG" 
                                            alt="User profile"
                                            className="avatar-img rounded-circle"
                                        />
                                    </div>
                                    <span className="profile-username">
                                        <span className="op-7" style={{ float: 'left' }}>{name} {surname}</span><br></br>
                                        <span className="fw-bold">{email}</span>
                                    </span>
                                </a>
                                <ul className="dropdown-menu dropdown-user animated fadeIn">
                                    <div className="dropdown-user-scroll scrollbar-outer">
                                        <li>
                                            <div className="user-box">
                                                <div className="avatar-lg">
                                                    <img
                                                        src="../assets/img/profile.jpg"
                                                        alt="User profile"
                                                        className="avatar-img rounded"
                                                    />
                                                </div>
                                                <div className="u-text">
                                                    <h4>Hizrian</h4>
                                                    <p className="text-muted">hello@example.com</p>
                                                    <a
                                                        href="profile.html"
                                                        className="btn btn-xs btn-secondary btn-sm"
                                                    >
                                                        View Profile
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                    </div>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default Topnav;
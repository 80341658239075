import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import Contributions from './Contributions/Contributions';
import SerPart from './ServiceParticipation';
import Login from './Login';
import MembersList from './Member_Management/Members';
import Attendance from './Event_Management/Attendance';
import PledgeManagement from './Contributions/PledgeManagement';
import VolunteerOpportunities from './Event_Management/VolunteerOpp';
import Events from './Event_Management/Events';
import Ministries from './Ministry_Management/Ministries';
import CellGroups from './Cell_Groups/CellGrps';
import EditMember from './Member_Management/EditMember';
import AddMember from './Member_Management/AddMember';
import ViewMember from './Member_Management/ViewMember';
import EditPastMember from './Past_Members/EditPastMember';
import PastMembersList from './Past_Members/PastMembers';
import EditVisitor from './Visitors/EditVisitors';
import Visitor from './Visitors/Visitors';
import SatisfiedPledges from './Contributions/SatisfiedPleges';
import MinistryMembers from './Ministry_Management/MinistryMembers';
import Sermons from './Media/sermons';
import Notifications from './Media/Notifications';
import Users from './Admin/Users';
import Password from './Admin/ChangePassword';
import CellMembers from './Cell_Groups/CellMembers';
import Projects from './Contributions/Projects';
import PrayerReq from './Media/PrayerReq';
import Donations from './Contributions/Donations';
import Children from './Children/Children';
import JoinRequests from './Ministry_Management/JoinRequests';
import MinistryLeaders from './Ministry_Management/MinistryLeaders';

function App() {

    return (
        <BrowserRouter>
            <div className='App'>
                <Routes>
                    <Route path='/' element={<Login/>}></Route>
                    <Route path='/dashboard' element={<Dashboard />}></Route>
                    <Route path='/contributions' element={<Contributions />}></Route>
                    <Route path='/donations' element={<Donations />}></Route>
                    <Route path='/pledges' element={<PledgeManagement />}></Route>
                    <Route path='/projects' element={<Projects />}></Route>
                    <Route path='/satisfiedpledges' element={<SatisfiedPledges />}></Route>
                    <Route path='/service/participation' element={<SerPart />}></Route>
                    <Route path='/members' element={<MembersList />}></Route>
                    <Route path='/children' element={<Children />}></Route>
                    <Route path='/pastmembers' element={<PastMembersList/>}></Route>
                    <Route path='/edit-pastmember/:id' element={<EditPastMember/>}></Route>
                    <Route path='/edit-member/:id' element={<EditMember/>}></Route>
                    <Route path='/add-member/' element={<AddMember/>}></Route>
                    <Route path='/visitors' element={<Visitor/>}></Route>
                    <Route path='/edit-visitor/:id' element={<EditVisitor/>}></Route>
                    <Route path='/attendance' element={<Attendance />}></Route>
                    <Route path='/volunteeropptunities' element={<VolunteerOpportunities />}></Route>
                    <Route path='/events' element={<Events/>}></Route>
                    <Route path='/ministries' element={<Ministries/>}></Route>
                    <Route path='/joinrequests' element={<JoinRequests/>}></Route>
                    <Route path='/cellgroups' element={<CellGroups/>}></Route>
                    <Route path='/cellmembers/:id' element={<CellMembers/>}></Route>
                    <Route path='/view-member/:id' element={<ViewMember/>}></Route>
                    <Route path='/ministrymembers/:id' element={<MinistryMembers/>}></Route>
                    <Route path='/ministryleaders/:id' element={<MinistryLeaders/>}></Route>
                    <Route path='/sermons' element={<Sermons/>}></Route>
                    <Route path='/notifications' element={<Notifications/>}></Route>
                    <Route path='/prayer-req' element={<PrayerReq/>}></Route>
                    <Route path='/users' element={<Users/>}></Route>
                    <Route path='/changepassword' element={<Password/>}></Route>
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown} from '@fortawesome/free-solid-svg-icons';
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { API_URL } from "../config";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { getCurrentDate } from '../Components/DateFunction';

const SatisfiedPledges = () => {
    const [pledges, setPledges] = useState([]);
    const [filteredPledges, setFilteredPledges] = useState([]);
    const [members, setMembers] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [projects, setProjects] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [formData, setFormData] = useState({
        MemberID: '',
        StartDate: '',
        EndDate: '',
        Amount: '',
        ProjectID: ''
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [startDateFilter, setStartDateFilter] = useState('');
    const [totalPledges, setTotalPledges] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [sortField, setSortField] = useState('StartDate');
    const [sortOrder, setSortOrder] = useState('asc');

    const fetchPledges = async () => {
        const response = await fetch(`${API_URL}/pledges/all/Satisfied`);
        const data = await response.json();
        setPledges(data);
        setFilteredPledges(data);
    };

    const fetchMembers = async () => {
        const response = await fetch(`${API_URL}/members`);
        const data = await response.json();
        setMembers(data);
        setFilteredMembers(data);
    };

    const fetchProjects = async () => {
        const response = await fetch(`${API_URL}/projects`);
        const data = await response.json();
        setProjects(data);
    };

    const calculateTotals = useCallback(() => {
        const totalPledgeCount = filteredPledges.length;
        const totalPledgeAmount = filteredPledges.reduce((sum, pledge) => sum + pledge.Amount, 0);
        setTotalPledges(totalPledgeCount);
        setTotalAmount(totalPledgeAmount);
    }, [filteredPledges]);

    useEffect(() => {
        fetchPledges();
        fetchMembers();
        fetchProjects();
    }, []);

    useEffect(() => {
        calculateTotals();
    }, [filteredPledges, calculateTotals]);

    const getMemberName = useCallback((memberID) => {
        const member = members.find(m => m.MemberID === memberID);
        return member ? `${member.Name} ${member.Surname}` : 'Unknown';
    }, [members]);

    const getProjectName = useCallback((projectID) => {
        const project = projects.find(p => p.ProjectID === projectID);
        return project ? project.ProjectName : 'Unknown';
    }, [projects]);

    useEffect(() => {
        setFilteredMembers(
            members.filter(member =>
                (member.Name && member.Name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (member.Email && member.Email.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        );
    }, [searchTerm, members]);

    useEffect(() => {
        const filtered = pledges.filter(pledge => {
            const memberNameMatches = getMemberName(pledge.MemberID).toLowerCase().includes(searchTerm.toLowerCase());
            const projectNameMatches = getProjectName(pledge.ProjectID).toLowerCase().includes(searchTerm.toLowerCase());
            const startDateMatches = !startDateFilter || new Date(pledge.StartDate) >= new Date(startDateFilter);
            return (memberNameMatches || projectNameMatches) && startDateMatches;
        });
        setFilteredPledges(filtered);
    }, [searchTerm, startDateFilter, pledges, members, projects, getMemberName, getProjectName]);

    // const handleAddPledge = () => {
    //     setFormData({
    //         MemberID: '',
    //         StartDate: '',
    //         EndDate: '',
    //         Amount: '',
    //         ProjectID: ''
    //     });
    //     setSearchTerm('');
    //     setShowAddModal(true);
    // };

    // const handleEditPledge = (pledge) => {
    //     setFormData(pledge);
    //     setShowEditModal(true);
    // };

    // const handleDeletePledge = async (pledgeID) => {
    //     if (window.confirm("Are you sure you want to delete this pledge?")) {
    //         await fetch(`${API_URL}/pledges/${pledgeID}`, {
    //             method: 'DELETE'
    //         });
    //         fetchPledges();
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetch(`${API_URL}/pledges`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
        });
        fetchPledges();
        setShowAddModal(false);
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        await fetch(`${API_URL}/pledges/${formData.PledgeID}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
        });
        fetchPledges();
        setShowEditModal(false);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
        const sortedPledges = [...filteredPledges].sort((a, b) => {
            return order === 'asc' ? (a[field] > b[field] ? 1 : -1) : (a[field] < b[field] ? 1 : -1);
        });
        setFilteredPledges(sortedPledges);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    const generatePDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(20);
        doc.text('New Life Covenant Church', 14, 22);
        doc.setFontSize(15);
        doc.text(`Satisfied Pledges Report ${getCurrentDate()}`, 14, 35);
        doc.setFontSize(12);
        doc.text(`Total Pledges: $${totalAmount.toFixed(2)}`, 14, 52);

        autoTable(doc, {
            startY: 62,
            head: [['Date', 'Member Name', 'Amount', 'Project']],
            body: filteredPledges.map(pledge => [
                new Date(pledge.StartDate).toLocaleDateString(),
                getMemberName(pledge.MemberID),
                `$${pledge.Amount.toFixed(2)}`,
                getProjectName(pledge.ProjectID)
            ]),
        });

        doc.save('satisfied_pledges_report.pdf');
    };

    return (
        <div>
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Topnav />
                    <div className="container">
                        <div className="page-inner">
                            <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
                                <h2 className="text-capitalize font-weight-bold">
                                    Satisfied Pledges ({totalPledges}) - Total Amount: ${totalAmount.toFixed(2)}
                                </h2>
                                <button className="btn btn-sm" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={generatePDF}>
                                    Generate PDF
                                </button>
                                {/* <button className="btn btn-sm" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={handleAddPledge}>
                                    <FontAwesomeIcon icon={faPlus} /> Add Pledge
                                </button> */}
                            </div>

                            <div className="form-group mb-4">
                                <div className="d-flex">
                                    <input
                                        type="text"
                                        className="form-control me-2"
                                        placeholder="Search by Member Name or Project Name"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        style={{ flex: '0 0 75%' }}
                                    />
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="Start Date"
                                        value={startDateFilter}
                                        onChange={(e) => setStartDateFilter(e.target.value)}
                                        style={{ flex: '0 0 25%' }}
                                    />
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th onClick={() => handleSort('StartDate')} style={{ cursor: 'pointer' }}>
                                                Date {getSortIcon('StartDate')}
                                            </th>
                                            <th onClick={() => handleSort('MemberID')} style={{ cursor: 'pointer' }}>
                                                Member Name {getSortIcon('MemberID')}
                                            </th>
                                            <th onClick={() => handleSort('Amount')} style={{ cursor: 'pointer' }}>
                                                Amount {getSortIcon('Amount')}
                                            </th>
                                            {/* <th onClick={() => handleSort('EndDate')} style={{ cursor: 'pointer' }}>
                                                End Date {getSortIcon('EndDate')}
                                            </th> */}
                                            <th onClick={() => handleSort('ProjectID')} style={{ cursor: 'pointer' }}>
                                                Project Name {getSortIcon('ProjectID')}
                                            </th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredPledges.map((pledge, index) => (
                                            <tr key={index}>
                                                <td>{new Date(pledge.StartDate).toLocaleDateString()}</td>
                                                <td>{getMemberName(pledge.MemberID)}</td>
                                                <td>${pledge.Amount.toFixed(2)}</td>
                                                {/* <td>{new Date(pledge.EndDate).toLocaleDateString()}</td> */}
                                                <td>{getProjectName(pledge.ProjectID)}</td>
                                                {/* <td>
                                                    <div className="d-flex align-items-center">
                                                        <button
                                                            type="button"
                                                            className="btn btn-link"
                                                            onClick={() => handleEditPledge(pledge)}
                                                        >
                                                            <FontAwesomeIcon icon={faEdit} className="btn-icon-append" />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-link"
                                                            onClick={() => handleDeletePledge(pledge.PledgeID)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} className="btn-icon-append" style={{ color: 'tomato' }} />
                                                        </button>
                                                    </div>
                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <Footer />
                    </footer>
                </div>
            </div>

            {/* Add Pledge Modal */}
            {showAddModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowAddModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Pledge</h5>
                                <button type="button" className="close" onClick={() => setShowAddModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>Member</label>
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            placeholder="Search by Name or Email"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <select
                                            className="form-control"
                                            value={formData.MemberID}
                                            onChange={(e) => setFormData({ ...formData, MemberID: e.target.value })}
                                            required
                                        >
                                            <option value="">Select Member</option>
                                            {filteredMembers.map(member => (
                                                <option key={member.MemberID} value={member.MemberID}>
                                                    {member.Name} {member.Surname} {member.Email ? `(${member.Email})` : ''}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Start Date</label>
                                        <input type="date" className="form-control" value={formData.StartDate} onChange={(e) => setFormData({ ...formData, StartDate: e.target.value })} required />
                                    </div>
                                    {/* <div className="form-group">
                                        <label>End Date</label>
                                        <input type="date" className="form-control" value={formData.EndDate} onChange={(e) => setFormData({ ...formData, EndDate: e.target.value })} required />
                                    </div> */}
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <input type="number" className="form-control" value={formData.Amount} onChange={(e) => setFormData({ ...formData, Amount: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label>Project</label>
                                        <select
                                            className="form-control"
                                            value={formData.ProjectID}
                                            onChange={(e) => setFormData({ ...formData, ProjectID: e.target.value })}
                                            required
                                        >
                                            <option value="">Select Project</option>
                                            {projects.map(project => (
                                                <option key={project.ProjectID} value={project.ProjectID}>
                                                    {project.ProjectName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowAddModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Add Pledge</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Pledge Modal */}
            {showEditModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={() => setShowEditModal(false)}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Pledge</h5>
                                <button type="button" className="close" onClick={() => setShowEditModal(false)}>&times;</button>
                            </div>
                            <form onSubmit={handleUpdate}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>Member</label>
                                        <select
                                            className="form-control"
                                            value={formData.MemberID}
                                            disabled // Disable the member selection
                                        >
                                            {filteredMembers.map(member => (
                                                <option key={member.MemberID} value={member.MemberID}>
                                                    {member.Name} {member.Surname} {member.Email ? `(${member.Email})` : ''}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Start Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={formData.StartDate.slice(0, 10)}
                                            onChange={(e) => setFormData({ ...formData, StartDate: e.target.value })}
                                            required
                                        />
                                    </div>
                                    {/* <div className="form-group">
                                        <label>End Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={formData.EndDate.slice(0, 10)}
                                            onChange={(e) => setFormData({ ...formData, EndDate: e.target.value })}
                                            required
                                        />
                                    </div> */}
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={formData.Amount}
                                            onChange={(e) => setFormData({ ...formData, Amount: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Project</label>
                                        <select
                                            className="form-control"
                                            value={formData.ProjectID}
                                            onChange={(e) => setFormData({ ...formData, ProjectID: e.target.value })}
                                            required
                                        >
                                            <option value="">Select Project</option>
                                            {projects.map(project => (
                                                <option key={project.ProjectID} value={project.ProjectID}>
                                                    {project.ProjectName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update Pledge</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SatisfiedPledges;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { API_URL } from "../config";
import Swal from 'sweetalert2';

const JoinRequests = () => {
    const [members, setMembers] = useState([]);
    const [ministries, setMinistries] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [filter, setFilter] = useState('');

    // Pagination and sorting state
    const [currentPage, setCurrentPage] = useState(1);
    const [membersPerPage, setMembersPerPage] = useState(50);
    const [currentDate, setCurrentDate] = useState('');

    const navigate = useNavigate();

    const fetchMinistries = async () => {
        const response = await fetch(`${API_URL}/ministries`);
        const data = await response.json();
        setMinistries(data);
    };

    const getMinistryName = (ministryID) => {
        const ministry = ministries.find(m => m.MinistryID === ministryID);
        return ministry ? `${ministry.Name}` : 'Unknown';
    };

    useEffect(() => {
        fetchMembers();
        fetchMinistries();
        setCurrentDate(getCurrentDate());
    }, []);

    const fetchMembers = async () => {
        const response = await fetch(`${API_URL}/ministries/ministry/mem/req/join`);
        const data = await response.json();
        setMembers(data);
        setFilteredMembers(data);
    };

    const handleFilterChange = (e) => {
        const value = e.target.value;
        setFilter(value);
        const filtered = members.filter(member => 
            member.Name.toLowerCase().includes(value.toLowerCase()) ||
            member.Surname.toLowerCase().includes(value.toLowerCase()) ||
            getMinistryName(member.MinistryID).toLowerCase().includes(value.toLowerCase())
        );
        setFilteredMembers(filtered);
    };

    const handleEditMember = async (member) => {
        try {
            const response = await fetch(`${API_URL}/ministrymembers/approve/${member.MemberID}/${member.MinistryID}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.ok) {
                Swal.fire({
                    text: "Updated Successfully",
                    icon: "success"
                });
                fetchMembers();
            } else {
                Swal.fire({
                    text: "Failed to update",
                    icon: "error"
                });
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                text: "Failed to update, check your network connection!",
                icon: "error"
            });
        }
    };

    const indexOfLastMember = currentPage * membersPerPage;
    const indexOfFirstMember = indexOfLastMember - membersPerPage;
    const currentMembers = filteredMembers.slice(indexOfFirstMember, indexOfLastMember);
    const totalPages = Math.ceil(filteredMembers.length / membersPerPage);

    const handleRowsPerPageChange = (e) => {
        setMembersPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(20);
        doc.text('New Life Covenant Church', 14, 22);
        doc.setFontSize(15);
        doc.text(`Members Requesting to Join Ministries: ${currentDate}`, 14, 35);
        doc.setFontSize(12);
        doc.text(`Total: ${filteredMembers.length}`, 14, 52);

        autoTable(doc, {
            startY: 62,
            head: [['Ministry', 'Name', 'Surname', 'Phone']],
            body: filteredMembers.map(member => [
                getMinistryName(member.MinistryID),
                member.Name,
                member.Surname,
                member.Phone
            ]),
        });
        doc.save('members_requesting_to_join_ministries_report.pdf');
    };

    const generateCSV = () => {
        const csvData = [
            ['Ministry', 'Name', 'Surname', 'Phone'],
            ...filteredMembers.map(member => [
                getMinistryName(member.MinistryID),
                member.Name,
                member.Surname,
                member.Phone
            ])
        ];

        const csvContent = csvData.map(e => e.join(",")).join("\n");
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `members_requesting_to_join_ministries_report_${getCurrentDate()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    function getCurrentDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // Pagination function
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div>
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Topnav />
                    <div className="container"> 
                        <div className="page-inner">
                            <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
                                <h2 className="text-capitalize font-weight-bold">
                                    Requests to Join Ministries ({filteredMembers.length})
                                </h2>
                                <div>
                                    <button className="btn btn-sm" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={generateCSV}>
                                        Generate CSV
                                    </button>&nbsp;
                                    <button className="btn btn-sm" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={generatePDF}>
                                        Generate PDF
                                    </button>
                                </div>
                            </div>

                            {/* Filter Input */}
                            <div className="mb-3">
                                <input 
                                    type="text" 
                                    placeholder="Filter by ministry, name, or surname" 
                                    value={filter} 
                                    onChange={handleFilterChange} 
                                    className="form-control"
                                />
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped members-table" style={{ fontSize: '0.8rem' }}>
                                    <thead>
                                        <tr>
                                            <th>Ministry</th>
                                            <th>Name</th>
                                            <th>Surname</th>
                                            <th>Phone</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentMembers.map((member) => (
                                            <tr key={member.MemberID}>
                                                <td>{getMinistryName(member.MinistryID)}</td>
                                                <td>{member.Name}</td>
                                                <td>{member.Surname}</td>
                                                <td>{member.Phone}</td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <button type="button" className="btn btn-link" onClick={() => handleEditMember(member)}>
                                                            <Link style={{ textAlign: 'center' }}>Accept</Link>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {/* Pagination Controls */}
                            <nav>
                                <ul className="pagination justify-content-center">
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
                                            <button className="page-link" onClick={() => paginate(index + 1)}>
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                            {/* Rows per Page Selector at the bottom */}
                            <div className="mt-3">
                                <label htmlFor="rowsPerPage" className="mr-2">Rows per page:</label>
                                <select id="rowsPerPage" value={membersPerPage} onChange={handleRowsPerPageChange} className="form-control d-inline-block" style={{ width: 'auto' }}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={500}>500</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <Footer />
                    </footer>
                </div>
            </div>
        </div>  
    );
};

export default JoinRequests;

import React, { useState } from 'react';
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../config';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';

const AddMember = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        Name: '',
        Surname: '',
        Gender: '',
        Email: '',
        Phone: '',
        Address: '',
        Suburb: '',
        City: '',
        Country: '',
        Zone: '',
        MembershipStatus: 'Active',
        ProfilePicture: '',
        nxt_of_kin: '',
        nok_relationship: '',
        nok_phone: '',
        sponame: '',
        emerg_con_relationship: '',
        spophone: ''
    });
    // const [connResult, setConnResult] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        console.log(formData.Gender)
    };

    const validateForm = () => {
        const { Name, Address, Phone } = formData;
        return Name && Address && Phone;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch(`${API_URL}/members`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            setIsLoading(false);

            if (response.ok) {
                Swal.fire({
                    text: "Succefully Added",
                    icon: "success"
                });
                // setConnResult('saved');
                navigate('/members');
            } else {
                Swal.fire({
                    text: "Failed to add",
                    icon: "error"
                });
                // setConnResult('error');
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            Swal.fire({
                text: "Failed to add, check your network connection!",
                icon: "error"
            });
        }
    };

    const navigateBack = () => {
        navigate('/members');
    }

    return (
        <div className="wrapper">
            <Sidebar />
            <div className="main-panel">
                <Topnav />
                <div className="container">
                    <form onSubmit={handleSubmit} className="page-inner">
                        <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                            <h3 className="fw-bold mb-3">Add Member</h3>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <label htmlFor="Name">Name</label>
                                <input
                                    type="text"
                                    name="Name"
                                    id="Name"
                                    className="form-control"
                                    style={{ border: '0.5px solid darkblue' }}
                                    value={formData.Name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-lg-4">
                                <label htmlFor="Surname">Surname</label>
                                <input
                                    type="text"
                                    name="Surname"
                                    id="Surname"
                                    className="form-control"
                                    style={{ border: '0.5px solid darkblue' }}
                                    value={formData.Surname}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-lg-4">
                                <label htmlFor="Gender">Gender</label>
                                <select
                                    id="Gender"
                                    name="Gender"
                                    value={formData.Gender}
                                    onChange={handleChange}
                                    required
                                    className="form-control"
                                    style={{ border: '0.5px solid darkblue' }}
                                >
                                    <option value="">Select Gender</option>
                                    <option>Male</option>
                                    <option>Female</option>

                                </select>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <label htmlFor="Phone">Phone</label>
                                <input
                                    type="text"
                                    name="Phone"
                                    id="Phone"
                                    className="form-control"
                                    style={{ border: '0.5px solid darkblue' }}
                                    value={formData.Phone}
                                    onChange={handleChange}
                                    // required
                                />
                            </div>
                            <div className="col-lg-4">
                                <label htmlFor="Phone">Email</label>
                                <input
                                    type="text"
                                    name="Email"
                                    id="Email"
                                    className="form-control"
                                    style={{ border: '0.5px solid darkblue' }}
                                    value={formData.Email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-4">
                                <label htmlFor="Address">Address</label>
                                <input
                                    type="text"
                                    name="Address"
                                    id="Address"
                                    className="form-control"
                                    style={{ border: '0.5px solid darkblue' }}
                                    value={formData.Address}
                                    onChange={handleChange}
                                    // required
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <label htmlFor="Address">City</label>
                                <input
                                    type="text"
                                    name="City"
                                    id="City"
                                    className="form-control"
                                    style={{ border: '0.5px solid darkblue' }}
                                    value={formData.City}
                                    onChange={handleChange}
                                    // required
                                />
                            </div>
                            <div className="col-lg-4">
                                <label htmlFor="Address">Country</label>
                                <input
                                    type="text"
                                    name="Country"
                                    id="Country"
                                    className="form-control"
                                    style={{ border: '0.5px solid darkblue' }}
                                    value={formData.Country}
                                    onChange={handleChange}
                                    // required
                                />
                            </div>
                            <div className="col-lg-4">
                                <label htmlFor="Zone">Zone</label>
                                <select
                                    name="Zone"
                                    id="Zone"
                                    value={formData.Zone}
                                    onChange={handleChange}
                                    // required
                                    className="form-control"
                                    style={{ border: '0.5px solid darkblue' }}
                                >
                                    <option value="">Select Zone</option>
                                    <option>Eastern</option>
                                    <option>Western</option>
                                    <option>Northen</option>
                                    <option>Southern</option>
                                    <option>Central</option>
                                    <option>Chitungwiza</option>

                                </select>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <label htmlFor="MembershipStatus">Status</label>
                                <select
                                    name="MembershipStatus"
                                    id="MembershipStatus"
                                    value={formData.MembershipStatus}
                                    onChange={handleChange}
                                    required
                                    className="form-control"
                                    style={{ border: '0.5px solid darkblue' }}
                                >
                                    <option value="">Select Membership Status</option>
                                    <option>Active</option>
                                    <option>Inactive</option>
                                    <option>Visitor</option>
                                </select>
                            </div>
                        </div>
                        <div><br></br>
                            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                                <h3 className="fw-bold mb-3">Next of Kin</h3>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        name="nxt_of_kin"
                                        id="nxt_of_kin"
                                        className="form-control"
                                        style={{ border: '0.5px solid darkblue' }}
                                        value={formData.nxt_of_kin}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <label>Relationship</label>
                                    <input
                                        type="text"
                                        name="nok_relationship"
                                        id="nok_relationship"
                                        className="form-control"
                                        style={{ border: '0.5px solid darkblue' }}
                                        value={formData.nok_relationship}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <label>Phone</label>
                                    <input
                                        type="text"
                                        name="nok_phone"
                                        id="nok_phone"
                                        className="form-control"
                                        style={{ border: '0.5px solid darkblue' }}
                                        value={formData.nok_phone}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div><br></br>
                            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                                <h3 className="fw-bold mb-3">Spouse Contact</h3>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <label>Full Name</label>
                                    <input
                                        type="text"
                                        name="sponame"
                                        id="sponame"
                                        className="form-control"
                                        style={{ border: '0.5px solid darkblue' }}
                                        value={formData.sponame}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <label>Phone</label>
                                    <input
                                        type="text"
                                        name="spophone"
                                        id="spophone"
                                        className="form-control"
                                        style={{ border: '0.5px solid darkblue' }}
                                        value={formData.spophone}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className="row mt-4">
                            <div className="col-lg-12">
                                <button type="submit" className="btn btn-primary">Add Member</button>
                            </div>
                        </div> */}
                        <div className="row mt-4">
                            <div className="col-lg-8"></div>
                            <div className="col-lg-2">
                                <button type="button" className="btn form-control" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} onClick={navigateBack}>
                                    Back
                                </button>
                            </div>
                            <div className="col-lg-2">
                                {/* <button type="submit" className="btn form-control" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                    Add Member
                                </button> */}
                                {/* Loading Spinner */}
                                {isLoading && (
                                    <div style={{ marginTop: '8px', textAlign: 'center' }}>
                                        <ClipLoader loading={isLoading} size={35} color="blue" />
                                    </div>
                                )}

                                <button type="submit" className="btn form-control" style={{ backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }} disabled={isLoading}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddMember;
import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "../Footer";
import Sidebar from "../sidebar";
import Topnav from "../TopNav";
import { API_URL } from "../config";
import dateFormat from "dateformat";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { getCurrentDate } from "../Components/DateFunction";
import Swal from "sweetalert2";

const Attendance = () => {
    const [events, setEvents] = useState([]);
    const [attendanceRecords, setAttendanceRecords] = useState([]);
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({});
    const [eventId, setEventId] = useState("");
    const [regDate, setRegDate] = useState("");
    const [totalAttendance, setTotalAttendance] = useState("");

    useEffect(() => {
        fetchEvents();
        fetchAttendanceRecords();
    }, []);

    useEffect(() => {
        // Filter attendance records based on search input
        const filtered = attendanceRecords.filter(record => {
            return (
                record.type.toLowerCase().includes(searchInput.toLowerCase()) ||
                record.location.toLowerCase().includes(searchInput.toLowerCase()) ||
                record.theme.toString().toLowerCase().includes(searchInput.toLowerCase())
            );
        });
        setFilteredRecords(filtered);
    }, [searchInput, attendanceRecords]);

    const fetchEvents = async () => {
        try {
            const response = await axios.get(`${API_URL}/events-tasks`);
            setEvents(response.data);
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    };

    const fetchAttendanceRecords = async () => {
        try {
            const response = await axios.get(`${API_URL}/regatt/att/event`);
            setAttendanceRecords(response.data);
            setFilteredRecords(response.data); // Initialize the filtered records
        } catch (error) {
            console.error("Error fetching attendance records:", error);
        }
    };

    const handleAttendanceSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editMode) {
                await axios.put(`${API_URL}/regatt/${currentRecord.AttendanceID}`, {
                    EventID: eventId,
                    RegDate: regDate,
                    TotalAttendance: totalAttendance,
                });
            } else {
                await axios.post(`${API_URL}/regatt`, {
                    EventID: eventId,
                    RegDate: regDate,
                    TotalAttendance: totalAttendance,
                });
            }

            Swal.fire({
                text: "Successfully recorded attendance",
                icon: "success"
            });

            fetchAttendanceRecords(); // Refresh the attendance list
            closeModal();
        } catch (error) {
            console.error("Error recording attendance:", error);
            Swal.fire({
                text: "Failed to record attendance",
                icon: "error"
            });
        }
    };

    const openModal = (record = null) => {
        if (record) {
            setEditMode(true);
            setCurrentRecord(record);
            setEventId(record.EventID);
            setRegDate(record.RegDate);
            setTotalAttendance(record.TotalAttendance);
        } else {
            setEditMode(false);
            setEventId("");
            setRegDate("");
            setTotalAttendance("");
        }
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setEventId("");
        setRegDate("");
        setTotalAttendance("");
    };

    // const handleDelete = async (id) => {
    //     const confirmDelete = window.confirm("Are you sure you want to delete this record?");
    //     if (confirmDelete) {
    //         try {
    //             await axios.delete(`${API_URL}/regatt/${id}`);
    //             fetchAttendanceRecords(); // Refresh the attendance list
    //         } catch (error) {
    //             console.error("Error deleting attendance record:", error);
    //         }
    //     }
    // };

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });
    
        if (result.isConfirmed) {
            try {
                await axios.delete(`${API_URL}/regatt/${id}`);
                fetchAttendanceRecords(); // Refresh the attendance list
                
                Swal.fire({
                    text: "Attendance record successfully deleted",
                    icon: "success"
                });
            } catch (error) {
                console.error("Error deleting attendance record:", error);
                Swal.fire({
                    text: "Failed to delete attendance record",
                    icon: "error"
                });
            }
        } else {
            Swal.fire({
                text: "Deletion canceled",
                icon: "info"
            });
        }
    };
    

    const generatePDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(20);
        doc.text('New Life Covenant Church', 14, 22);
        doc.setFontSize(15);
        doc.text(`Events Report ${getCurrentDate()}`, 14, 35);
        doc.setFontSize(12);
        doc.text(`Total Events: ${filteredRecords.length}`, 14, 52);

        autoTable(doc, {
            startY: 62,
            head: [['Date', 'Name', 'Theme', 'Description', 'Location', 'Total Attendamce']],
            body: filteredRecords.map(record => [
                new Date(record.date).toLocaleDateString(),
                record.type,
                record.theme,
                record.description,
                record.location,
                record.TotalAttendance,
            ]),
        });

        doc.save('attendance_records_report.pdf');
    }

    const generateCSV = () => {
        const csvRows = [];

        // Add header row
        csvRows.push(['Date', 'Name', 'Theme', 'Description', 'Location', 'Total Attendance'].join(','));

        // Add data rows
        filteredRecords.forEach(record => {
            const row = [
                new Date(record.date).toLocaleDateString(),
                record.type,
                record.theme,
                record.description,
                record.location,
                record.TotalAttendance,
            ];
            csvRows.push(row.join(','));
        });

        // Create a CSV string
        const csvString = csvRows.join('\n');

        // Create a Blob from the CSV string
        const blob = new Blob([csvString], { type: 'text/csv' });

        // Create a link element to download the CSV
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'attendance_records_report.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };


    return (
        <div className="wrapper">
            <Sidebar />
            <div className="main-panel">
                <Topnav />

                <div className="container">
                    <div className="page-inner">
                        <div className="d-flex justify-content-between align-items-md-center flex-column flex-md-row pt-2 pb-4">
                            <h2>Attendance Tracking</h2>
                            <div>
                                <button className="btn btn-sm" onClick={generateCSV} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                    Generate CSV
                                </button> &nbsp;
                                <button className="btn btn-sm" onClick={generatePDF} style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                    Generate PDF
                                </button>
                            </div>
                        </div>
                        <div className="d-flex mb-3" style={{ width: '100%' }}>
                            <input
                                type="text"
                                placeholder="Search by name, theme or location, "
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                className="form-control"
                                style={{ flex: '1', marginRight: '10px' }} // Search bar takes more space
                            />
                            <button onClick={() => openModal()} className="btn " style={{ flexShrink: '0', backgroundColor: 'rgb(20,24,40)', color: '#ffffff' }}>
                                Record Attendance
                            </button>
                        </div>

                        <div className="table-responsive pt-3">
                            <table className="table table-striped events-table">
                                <thead>
                                    <tr>
                                        <th>Event</th>
                                        <th>Theme</th>
                                        <th>Date</th>
                                        {/* <th>Time</th> */}
                                        <th>Location</th>
                                        <th>Total Attendance</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredRecords.map((record) => (
                                        <tr key={record.AttendanceID}>
                                            <td>{record.type}</td>
                                            <td>{record.theme}</td>
                                            <td>{dateFormat(record.date, "yyyy-mm-dd")}</td>
                                            {/* <td>{record.time} - {record.endtime}</td> */}
                                            <td>{record.location}</td>
                                            <td>{record.TotalAttendance}</td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <button type="button" className="btn btn-link" onClick={() => openModal(record)}>
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                    <button type="button" className="btn btn-link" onClick={() => handleDelete(record.AttendanceID)}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Bootstrap Modal for Recording Attendance */}
                        {modalIsOpen && (
                            <div className="modal fade show" style={{ display: 'block' }} onClick={closeModal}>
                                <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                                    <div className="modal-content" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                                        <div className="modal-header">
                                            <h5 className="modal-title">{editMode ? "Edit Attendance" : "Record Attendance"}</h5>
                                            <button type="button" className="close" onClick={closeModal}>
                                                &times;
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleAttendanceSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor="eventId" className="modal-label">Event Name</label>
                                                    <select
                                                        id="eventId"
                                                        value={eventId}
                                                        onChange={(e) => setEventId(e.target.value)}
                                                        required
                                                        className="form-control"
                                                    >
                                                        <option value="">Select an event</option>
                                                        {events.map(event => (
                                                            <option key={event.id} value={event.id}>
                                                                {event.type}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="regDate" className="modal-label">Registration Date</label>
                                                    <input
                                                        type="date"
                                                        id="regDate"
                                                        value={regDate.slice(0, 10)}
                                                        onChange={(e) => setRegDate(e.target.value)}
                                                        required
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="totalAttendance" className="modal-label">Total Attendance</label>
                                                    <input
                                                        type="number"
                                                        id="totalAttendance"
                                                        value={totalAttendance}
                                                        onChange={(e) => setTotalAttendance(e.target.value)}
                                                        required
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
                                                    <button type="submit" className="btn btn-primary">{editMode ? "Update" : "Submit"}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <footer className="footer">
                    <Footer />
                </footer>
            </div>
        </div>
    );
};

export default Attendance;
